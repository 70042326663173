import moment from 'moment'
import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const startSelector = (state,start)=> start
const endSelector = (state,start,end)=> end

const getVideosBetweenDatesByDateByHour = createSelector(
  [mediasSelector,startSelector,endSelector],
  (medias,start,end)=> {
    // console.log('getVideosBetweenDatesByDateByHour',start,end)
    const timeline = Object.values(medias).filter( m =>
      m.type === 'video' && m.start !== undefined
       &&
      moment(m.start).isBetween(start,end)
    ).reduce((obj,v)=> {
      const date = moment(v.start).startOf('day').unix()
      const hour = moment(v.start).startOf('hour').unix()
      obj[date] = {
        ...(obj[date] ? obj[date] : {}),
      }
      obj[date][hour] = [
        ...(obj[date][hour] ? obj[date][hour] : []),
        v
      ]
      return obj
    },{})
    Object.keys(timeline).map( key => {
      const cursor = moment.unix(key).add(7,'hours')
      new Array(12).fill(0).forEach((x,i)=> {
        const ts = cursor.unix()
        // console.log(ts)
        timeline[key][ts] = [
          ...(timeline[key][ts] ? timeline[key][ts] : [])
        ]
        cursor.add(1,'hour')
      })
    })
    return timeline
  }
)

export default getVideosBetweenDatesByDateByHour