import React, { createContext, useState, useEffect, useContext } from 'react'

export const HighlightContext = createContext()

export const HighlightProvider = ({ children })=> {

  const [ highlighted, setHighlighted ] = useState([])

  const highlightContext = [
    highlighted,
    setHighlighted
  ]

  return <HighlightContext.Provider
      value={ highlightContext }
    >
      { children }
    </HighlightContext.Provider>
}

export const useHighlight = ()=> {

  const [ highlighted, setHighlighted ] = useContext(HighlightContext)

  const highlight = (selector,className)=> {
    // setHighlighted(highlighted.with(id))
    // setHighlighted([id])
    document.querySelectorAll(selector).forEach( e => e.classList.add(className))
  }

  const lowlight = (selector,className)=> {
    // setHighlighted(highlighted.without(id))
    // setHighlighted([])
    document.querySelectorAll(selector).forEach( e => e.classList.remove(className))
  }

  const isHighlighted = ({ id })=> {
    return highlighted.indexOf(id) !== -1
  }
  
  return {
    highlighted,
    setHighlighted,
    highlight,
    lowlight,
    isHighlighted,
  }
}