import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Fa } from '|/icons'

import {
  PARSE_TURTLE
} from '|/store/constants'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'

import styles from './main.styl'

const Burger = props => {

  const dispatch = useDispatch()
  const [ open, setOpen ] = useState(false)
  const [ refreshing, setRefreshing ] = useState(false)
  
  const _onToggleClick = (e)=> {
    setOpen(!open)
  }

  const _onRefreshClick = (e)=> {
    setRefreshing(true)
    dispatch({
      type: PARSE_TURTLE,
      callback: ()=> setRefreshing(false)
    })
  }

  return (
    <div
      className={[ styles.container, ( open ? styles.open : undefined ) ].join(' ')}
    >
      <div className={ styles.toggle }>
        <Button
          material={ false }
          link={ true }
          disabled={ refreshing }
          onClick={ _onToggleClick }
        ><Fa icon="bars" /></Button>
      </div>
      <div className={ styles.menu }>
        <div className={ styles.nav }>
          <Plink
            seo={ false }
            to={{
              search: {
                info: 'impressum',
              }
            }}
          >
            Impressum
          </Plink>
        </div>
        <div className={ styles.nav }>
          <Plink
            seo={ false }
            onClick={ _onRefreshClick }
          >
            { refreshing ? 'reloading data...' : 'Reload data'}
          </Plink>
        </div>
      </div>
    </div>
  )
}

export default Burger