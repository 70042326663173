import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = (state)=> state[MEDIAS].list
const URIsSelector = (state,uris)=> uris

const getMediasByURIs = createSelector(
  [mediasSelector,URIsSelector],
  (medias,uris)=> {
    console.log('getMediasByURIs')
    return Object.values(medias).filter( o => uris.indexOf(o.id) !== -1 )
  }
)

export default getMediasByURIs

export const createGetMediasByURIs = ()=> getMediasByURIs