import { createSelector } from 'reselect'

import { RESIDENCIES } from '|/settings'

const residenciesSelector = state => state[RESIDENCIES].list
const idSelector = (state,id)=> id

const getResidencies = (residencies,id)=> {
  return residencies[id]
}

export default createSelector(
  [residenciesSelector,idSelector],
  getResidencies
)