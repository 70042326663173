import moment from 'moment'
import { createSelector } from 'reselect'

import { ANNOTATIONS } from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const timestampSelector = (state,timestamp)=> timestamp

const getAnnotationsByTimestamp = (annotations,timestamp)=> {
  console.log('getAnnotationsByTimestamp',timestamp)
  const time = moment.unix(timestamp)
  return Object.values(annotations).filter( a =>
    time.isBetween(moment(a.start),moment(a.end),'second','[)')
  )
}

export default createSelector(
  [annotationsSelector,timestampSelector],
  getAnnotationsByTimestamp
)