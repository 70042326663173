require('./utils/prototypes')
// import registerServiceWorker from './registerServiceWorker'
import React from 'react'
import { render } from 'react-dom'

import Root from './Root'

render(
	<Root/>
,document.getElementById('root'))

// registerServiceWorker()