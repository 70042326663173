import { useContext } from 'react'
import { __RouterContext } from 'react-router'
import {
  search2obj,
} from '|/utils'

export const useLocation = ()=> {
  const context = useContext(__RouterContext)
  return context.location
}

export const useMatch = ()=> {
  const context = useContext(__RouterContext)
  return context.match
}

export const useParams = ()=> {
  const context = useContext(__RouterContext)
  return context.match.params
}

export const useQuery = ()=> {
  const context = useContext(__RouterContext)
  return search2obj(context.location.search)
}