import moment from 'moment'
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { ResidencyContext } from '|/contexts/Residency'
import { getVideosBetweenDatesByDateByHour } from '|/store/Medias/selectors'

import Timeline from './Timeline'

import styles from './main.styl'

const Dates = (props)=> {

  const { start, setStart, end, setEnd, duration, setDuration } = useContext(ResidencyContext)
  const cachedGetVideosBetweenDatesByDateByHour = useMemo(()=> getVideosBetweenDatesByDateByHour,[start,end])
  const dates = useSelector( state => cachedGetVideosBetweenDatesByDateByHour(state,start,end))

  useEffect(()=> {
    const start = moment(props.start)
    const end = moment(props.end)
    const duration = moment.duration(end.diff(start))

    setStart(start)
    setEnd(end)
    setDuration(duration)
  },[])

  return (
    <div
      className={ styles.timelines }
    >
      { Object.keys(dates).map((key,i)=>
        <Timeline key={ `date-${key}` } index={ i } timestamp={ key } hours={ dates[key] } />
      )}
    </div>
  )
}

Dates.defaultProps = {
  video: PropTypes.object
}

export default Dates