import React from 'react'

import styles from './main.styl'

const Loader = props => (
  <div
    className={ styles.loader }
    style={{ ...props.style }}
  >
    <div className={ styles.one } />
    <div className={ styles.two } />
    <div className={ styles.three } />
    <div className={ styles.four } />
    <div className={ styles.five } />
  </div>
)

export default Loader