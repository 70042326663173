import React, { useMemo, useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'

import { getActionGroups } from '|/store/Actions/selectors'

import ActionGroups from './ActionGroups'
import Actions from './Actions'
import Records from './Records'

import styles from './main.styl'

const Context = props => {

  const { action_group_ids, action_ids, record_ids } = useQuery()
  const [ aids, setAids ] = useState([])
  const [ agids, setAgids ] = useState([])
  const [ rids, setRids ] = useState([])

  useEffect(()=> {
    if(!isEqual(action_group_ids,agids))
      setAgids(action_group_ids)
  },[ action_group_ids ])

  useEffect(()=> {
    if(!isEqual(action_ids,aids))
      setAids(action_ids)
  },[ action_ids ])

  useEffect(()=> {
    if(!isEqual(record_ids,rids))
      setRids(record_ids)
  },[ record_ids ])

  const cachedActionGroups = useMemo(()=> <ActionGroups actionGroupIds={ agids } /> ,[ agids ])
  const cachedActions = useMemo(()=> <Actions actionIds={ aids } actionGroupIds={ agids } /> ,[ aids, agids ])
  const cachedRecords = useMemo(()=> <Records actionIds={ aids } recordIds={ rids } /> ,[ aids, rids ])

  return (
    <Fragment>
      <div className={ styles.header }>
        <h1>Prozess</h1>
      </div>
      <div className={ styles.context }>
        { cachedActionGroups }
        { cachedActions }
        { cachedRecords }
      </div>
    </Fragment>
  )
}

export default Context