import React from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import styles from './main.styl'

const Button = ({ el, icon, iconStyle, round, link, kind, material, className, children, ...props })=> {
  const classList = [ styles.container, className ]
  const El = el
  if(round) classList.push(styles.round)
  if(link) classList.push(styles.link)
  if(kind) classList.push(styles[kind])
  if(material) classList.push(styles.material)
  return (
    <El { ...props } className={ classList.join(' ') }>
      { children }
      { icon &&
        <Fa className={ styles.icon } style={ iconStyle } icon={ icon } />
      }
    </El>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  material: PropTypes.bool,
  round: PropTypes.bool,
  link: PropTypes.bool,
  kind: PropTypes.string,
  el: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
  icon: undefined,
  iconStyle: 'fal',
  material: true,
  round: false,
  link: false,
  el: 'button',
}

export default Button