import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Action from './Action'

import styles from './main.styl'

const Actions = ({ actions })=> {

  return (
    <div className={ `${styles.actions}` }>
      { actions.map((action)=>
        <Action key={ action.id } reference={ action } />
      )}
    </div>
  )
}

Actions.defaultProps = {
  video: PropTypes.object
}

export default Actions