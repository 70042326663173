export default {
  setItem(key, val) {
    try {
      return window.localStorage.setItem(key, JSON.stringify(val))
    } catch(e) {
      return undefined
    }
  },
  getItem(key) {
    try{
      return JSON.parse(window.localStorage.getItem(key))
    } catch(e) {
      return undefined
    }
  },
  removeItem(key) {
    try {
      return window.localStorage.removeItem(key)
    } catch(e) {
      return undefined
    }
  }
}