import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'

import { createGetObjectByType } from '|/store/Timelines/selectors/getObjectByType'

import styles from './main.styl'

const Dot = ({ reference })=> {

  const {
    id,
    type
  } = reference

  // const { action_group_ids, action_ids } = useQuery()
  const getObjectByType = useMemo(createGetObjectByType,null)
  const object = useSelector( state => getObjectByType(state,type,id))
  const [ hover, setHover ] = useState(false)
  const [ highlighted, setHighlighted ] = useState(false)

  // useEffect(()=> {
  //   if(object && (action_group_ids || action_ids)) {
  //     const ag_array = Array.isArray(action_group_ids) ? action_group_ids : [action_group_ids]
  //     const a_array = Array.isArray(action_ids) ? action_ids : [action_ids]
  //     const id_array = [ ...ag_array, ...a_array ]
  //     if(
  //       (id_array.indexOf(object.id) !== -1)
  //         ||
  //       (object.groups && id_array.hasIn(object.groups))
  //     )
  //       setHighlighted(true)
  //     else
  //       setHighlighted(false)
  //   }
  // }, [ action_group_ids, action_ids ])

  const onMouseEnter = (e)=> {
    setHover(true)
  }

  const onMouseLeave = (e)=> {
    setHover(false)
  }
  
  return (
    <div
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      className={
        [
          styles.dot,
          hover ? styles.hover : undefined,
          highlighted ? styles.highlighted : undefined,
      ].join(' ')}
    >
    </div>
  )
}

Dot.defaultProps = {
  reference: PropTypes.object
}

export default Dot