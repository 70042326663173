import moment from 'moment'
import React, { useMemo, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { ZoomContext } from '|/contexts/Zoom'
import { useHighlight } from '|/contexts/Highlight'

import { usePlayer } from '|/components/Player'

import Artefacts from './Artefacts'
import Actions from './Actions'
import Dot from './Dot'

import Plink from '|/helpers/Plink'

import {
  ARTEFACTS,
  ACTIONS,
} from '|/settings'

import styles from './main.styl'

const Annotation = ({ annotation, hour })=> {

  // const player = usePlayer()
  // const { highlighted } = useHighlight()
  // const [ highlight, setHighlight ] = useState(false)
  const { zoom } = useContext(ZoomContext)
  const [ artefacts, setArtefacts ] = useState([])
  const [ actions, setActions ] = useState([])
  const [ other, setOther ] = useState([])
  const [ start, setStart ] = useState(0)
  const [ relative_start, setRelative_start ] = useState(0)
  const [ duration, setDuration ] = useState(0)
  
  const {
    shows
  } = annotation

  const _onAnnotationClick = (e)=> {
    // e.stopPropagation()
    // player.jumpTo(moment(annotation.start))
  }

  // useEffect(()=> {
  //   // console.log('Hightlighted!',highlighted.indexOf(annotation.id) !== -1)
  //   const should_highlight = highlighted.indexOf(annotation.id) !== -1
  //   if(should_highlight && !highlight || !should_highlight && highlight)
  //     setHighlight(should_highlight)
  // },[ highlighted ])

  useEffect(()=> {
    // console.log('Rendering annotation')
    const artefacts = shows.filter( s => s.type === ARTEFACTS )
    const actions = shows.filter( s => s.type === ACTIONS )
    const other = shows.filter( s => s.type !== ACTIONS )
    
    const start = moment(annotation.start)
    const start_hour = hour
    const relative_start = moment.duration(start.diff(start_hour)).asSeconds()
    const duration = moment.duration(annotation.duration).asSeconds()

    setRelative_start(relative_start)
    setDuration(duration)

    setArtefacts(artefacts)
    setActions(actions)
    setOther(other)
    setStart(start.unix())
  },[ annotation ])

  const cachedActions = useMemo(()=> <Actions actions={ shows } />,[])

  return (
    <div
      data-annotation={ annotation.id }
      className={ [ styles.annotation ].join(' ') }
      style={{
        top: `${relative_start/3600*100}%`,
        height: `${duration/3600*100}%`,
      }}
      onClick={ _onAnnotationClick }
      id={`annotation_${annotation.id}`}
    >
      {/*<Artefacts artefacts={ artefacts } />*/}
      { cachedActions }
      { other.map( o =>
        <div
          key={`${annotation.id}-${o.id}`}
          className={ styles.dot }
          data-type={ o.type }
          data-records={ o.id+';' }
        />
      )}
    </div>
  )
}

Annotation.defaultProps = {
  annotation: PropTypes.object
}

export default Annotation