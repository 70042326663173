import { fork, all } from 'redux-saga/effects'
import Auth from './auth/sagas'
import Inventory from './Inventory/sagas'
import Actors from './Actors/sagas'
import Artefacts from './Artefacts/sagas'
import Timelines from './Timelines/sagas'
import Actions from './Actions/sagas'
import Locations from './Locations/sagas'
import Materials from './Materials/sagas'
import Tools from './Tools/sagas'
import Machines from './Machines/sagas'
import Annotations from './Annotations/sagas'
import Medias from './Medias/sagas'
import Projects from './Projects/sagas'
import Bibliography from './Bibliography/sagas'

export default function* sagas() {
	yield all([
    fork(Auth),
    fork(Inventory),
    fork(Actors),
    fork(Actions),
    fork(Artefacts),
    fork(Locations),
    fork(Materials),
    fork(Timelines),
    fork(Tools),
    fork(Machines),
    fork(Annotations),
    fork(Medias),
    fork(Projects),
    fork(Bibliography),
	])
}
