import moment from 'moment'
import React, { useState, useEffect, useLayoutEffect, useContext, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '|/hooks/router'
import { useSelector } from 'react-redux'
import { usePlayer } from '|/components/Player'
import { useHighlight } from '|/contexts/Highlight'

import { ResidencyContext } from '|/contexts/Residency'
import { getVideosBetweenDatesByHour } from '|/store/Medias/selectors'

import Hour from './Hour'

import styles from './main.styl'

const Timeline = ({ timestamp, hours, index })=> {

  if(Object.keys(hours).length === 0) return null

  const { action_group_ids, action_ids, record_ids } = useQuery()
  const { highlight, lowlight } = useHighlight()
  const hoverIndicator = useRef(null)
  const [ label, setLabel ] = useState('')
  const { currentTime, playing } = usePlayer()
  const [ day ] = useState(moment.unix(timestamp))
  const [ is_playing, setIs_playing ] = useState(index === 0)

  const _onMouseMove = (e)=> {
    if(hoverIndicator.current) {
      const mouse_x = e.clientX
      const mouse_y = e.clientY
      const bounds = e.currentTarget.getBoundingClientRect()
      const target_top = bounds.top
      const target_left = bounds.left
      const target_width = bounds.width
      const target_height = bounds.height
      const relative_x = mouse_x - target_left
      const relative_y = mouse_y - target_top
      const factor_x = relative_x / target_width
      const factor_y = relative_y / target_height
      hoverIndicator.current.style.top = `${factor_y*100}%`
    }
  }

  useEffect(()=> {
    setLabel(moment.unix(timestamp).format('YYYY.MM.DD'))
  },[ timestamp ])

  useLayoutEffect(()=> {
    if(playing) {
      const playhead = moment.unix(currentTime)
      if(playhead.isBetween(day,day.clone().endOf('day'))) {
        setIs_playing(true)
      } else {
        setIs_playing(false)
      }
    }
  },[ currentTime ])

  useLayoutEffect(()=> {
    lowlight(`[data-action],[data-action-groups]`,'selected')
    if(action_ids) {
      const a_array = Array.isArray(action_ids) ? action_ids : [action_ids]
      a_array.map( id => {
        highlight(`[data-action="${id}"]`,'selected')
      })
    } else if(action_group_ids) {
      const ag_array = Array.isArray(action_group_ids) ? action_group_ids : [action_group_ids]
      ag_array.map( gid => {
        highlight(`[data-action-groups*="${gid};"]`,'selected')
      })
    }
  },[ action_group_ids, action_ids ])

  useLayoutEffect(()=> {
    lowlight(`[data-records]`,'selected')
    if(record_ids) {
      const r_array = Array.isArray(record_ids) ? record_ids : [record_ids]
      r_array.map( id => {
        highlight(`[data-records*="${id};"]`,'selected')
      })
    }
  },[ record_ids ])

  return (
    <div
      className={[ styles.timeline, (is_playing ? 'playing' : undefined) ].join(' ')}
      onMouseMove={ _onMouseMove }
    >
      <div
        ref={ hoverIndicator }
        className={ styles.hoverIndicator }
      />
      <div className={ styles.label }>{ label }</div>
      <div className={ styles.hours }>
        { Object.keys(hours).map((key)=>
          <Hour
            key={ `hour-${key}` }
            timestamp={ key }
            videos={ hours[key] }
          />
        )}
      </div>
    </div>
  )
}

Timeline.defaultProps = {
  timestamp: PropTypes.string,
  hours: PropTypes.object,
}

export default Timeline