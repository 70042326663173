import throttle from 'lodash/throttle'
import moment from 'moment'
import React, { Fragment, useState, useRef, useEffect, useLayoutEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'

import { getVideoByMadekId } from '|/store/Medias/selectors'
import { usePlayer } from './index'
import useVideo from './useVideo'

import Loader from '|/helpers/Loader'

import styles from './main.styl'

const Video = ({ object, timestamp })=> {

  const [ animationFrame, setAnimationFrame ] = useState(null)
  const { video, setSrc, setMuted, setRef, canplay, playing, error, currentTime } = useVideo()
  const player = usePlayer()

  const _onPlayerClick = (e)=> {
    if(player.playing) {
      player.pause()
      player.jumpTo(player.currentTime)
    } else {
      player.play()
    }
  }

  const _onMuteClick = (e)=> {
    if(player.unmuted !== object.id)
      player.setUnmuted(object.id)
    else
      player.setUnmuted(undefined)
  }

  useEffect(()=> {
    return ()=> {
      // console.log('Going offline',object.id)
      cancelAnimationFrame(animationFrame)
      // player.setStatus(object.id,false)
    }
  },[])

  useEffect(()=> {
    if(video && timestamp)
      setAnimationFrame(requestAnimationFrame(()=> video.currentTime = moment.duration(moment.unix(timestamp).diff(object.start)).asSeconds()))
  },[ timestamp, video ])

  useEffect(()=> {
    if(video) {
      video.muted = player.unmuted !== object.id
    }
  },[ player.unmuted, player.playing ])

  useEffect(()=> {
    setSrc(object.madek_asset_url)
  },[ object ])

  useEffect(()=> {
    player.setStatus(object.id,canplay)
  },[ canplay ])

  useLayoutEffect(()=> {
    if(video) {
      if(player.playing) {
        video.play()
      } else {
        video.pause()
      }
    }
  },[ player.playing ])

  useEffect(()=> {
    if(video && player.playing) {
      const shouldHaveCurrentTime = moment.duration(moment.unix(player.currentTime).diff(object.start)).asSeconds()
      const delta = Math.abs(Math.round(video.currentTime) - shouldHaveCurrentTime)
      if(delta > 2) {
        // console.log(player.currentTime)
        // console.log(video.currentTime)
        // console.log(shouldHaveCurrentTime)
        // console.log('Out of sync:',delta)
        // video.currentTime = shouldHaveCurrentTime
        setAnimationFrame(requestAnimationFrame(()=> video.currentTime = shouldHaveCurrentTime))
      }
    }
  },[ player.currentTime ])

  useEffect(()=> {
    if(video && !player.playing && player.currentTime) {
      // video.pause()
      setAnimationFrame(requestAnimationFrame(()=> video.currentTime = moment.duration(moment.unix(player.currentTime).diff(object.start)).asSeconds()))
    }
    // if(video) {
    //   if(video.readyState !== 4)
    //     player.setStatus(object.id,false)
    //   if(video.readyState === 4)
    //     player.setStatus(object.id,true)
    // }
  },[ player.currentTime ])

  return (
    <Fragment>
      { !canplay &&
        <Loader />
      }
      <video
        ref={ setRef }
      />
      <div
        className={ styles.controls }
      >
        <div
          className={ styles.playpause }
          onClick={ _onPlayerClick }
        ></div>
        <div
          className={ styles.mute }
          onClick={ _onMuteClick }
        ></div>
      </div>
    </Fragment>
  )
}

export default Video