import {
  AUTHENTICATING,
  AUTHENTICATED,
  SET_REDIRECT,
  CLEAR_REDIRECT,
  LOGGING_IN,
  LOGGED_IN,
  LOGGED_OUT,
  GO_OFFLINE
} from '../constants'

const INITIAL_STATE = {
  authenticated: false,
  authenticating: true,
  offline: false,
  user: null,
  error: false,
  redirectTo: '/'
}

export default (state=INITIAL_STATE,action)=> {
  switch(action.type) {
    case AUTHENTICATING:
      return {
        ...state,
        authenticating: true,
        authenticated: false
      }
    case AUTHENTICATED:
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        error: false,
        offline: false
      }
    case SET_REDIRECT:
      return {
        ...state,
        redirectTo: action.redirectTo
      }
    case CLEAR_REDIRECT:
      return {
        ...state,
        redirectTo: null
      }
    case GO_OFFLINE:
      return {
        ...state,
        offline: true,
        authenticating: false,
        error: action.error
      }
    case LOGGING_IN:
      return {
        ...state,
        authenticating: true,
        authenticated: false
      }
    case LOGGED_IN:
      const { user } = action
      return {
        ...state,
        authenticated: true,
        authenticating: false,
        offline: false,
        user
      }
    case LOGGED_OUT:
      return {
        ...state,
        authenticated: false,
        authenticating: false,
        user: null,
        error: action.error
      }
  }
  return state
}