require('setimmediate')
import { all, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'
import { TURTLE_URL } from '|/settings'

import {
  PARSE_TURTLE,
  PARSING_TURTLE,
  PARSED_TURTLE,
} from '|/store/constants'

const store = getStore()
const fetcher = new lib.Fetcher(store)

function* parseTurtle({ callback }) {
  yield put({
    type: PARSING_TURTLE
  })

  const response = yield fetcher.load(TURTLE_URL)
  yield put({
    type: PARSED_TURTLE
  })
  if(callback)
    callback()
}

export default function* inventory(App) {
  yield all([
    takeEvery(PARSE_TURTLE,parseTurtle),
  ])
}
