import React, { useMemo, useState, useEffect, useLayoutEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'
import { useHighlight } from '|/contexts/Highlight'

import { getResidencies } from '|/store/Timelines/selectors'

import { ZoomProvider } from '|/contexts/Zoom'
import { ResidencyProvider } from '|/contexts/Residency'

import Dates from './Dates'

import styles from './main.styl'

const Residency = ({ residency })=> {

  const cachedDates = useMemo(()=> {
    return <Dates start={ residency.start } end={ residency.end } />
  },[ residency.start, residency.end ])

  return (
    <div className={ styles.residency } key={ residency.id }>
      <ResidencyProvider>
        { cachedDates }
      </ResidencyProvider>
    </div>
  )
}

export default Residency