// AUTH
export const AUTHENTICATE = 'authenticate'
export const AUTHENTICATING = 'authenticating'
export const AUTHENTICATED = 'authenticated'
export const SET_REDIRECT = 'SET_REDIRECT'
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT'
export const IDENTIFY = 'IDENTIFY'
export const IDENTIFYING = 'IDENTIFYING'
export const IDENTIFIED = 'IDENTIFIED'
export const LOG_IN = 'log_in'
export const LOGGING_IN = 'logging_in'
export const LOGGED_IN = 'logged_in'
export const LOG_OUT = 'log_out'
export const LOGGED_OUT = 'logged_out'
export const GO_OFFLINE = 'go_offline'

// CAT
export const FETCH_CAT = 'fetch_cat'
export const FETCHING_CAT = 'fetching_cat'
export const FETCHED_CAT = 'fetched_cat'
export const FETCHING_CAT_FAILED = 'fetching_cat_failed'

// TURTLE
export const PARSE_TURTLE = 'PARSE_TURTLE'
export const PARSING_TURTLE = 'PARSING_TURTLE'
export const PARSED_TURTLE = 'PARSED_TURTLE'
export const PARSING_TURTLE_FAILED = 'PARSING_TURTLE_FAILED'

// ACTORS
export const PARSE_ACTORS = 'PARSE_ACTORS'
export const PARSING_ACTORS = 'PARSING_ACTORS'
export const PARSED_ACTORS = 'PARSED_ACTORS'
export const PARSING_ACTORS_FAILED = 'PARSING_ACTORS_FAILED'

// ACTORS
export const PARSE_ARTEFACTS = 'PARSE_ARTEFACTS'
export const PARSING_ARTEFACTS = 'PARSING_ARTEFACTS'
export const PARSED_ARTEFACTS = 'PARSED_ARTEFACTS'
export const PARSING_ARTEFACTS_FAILED = 'PARSING_ARTEFACTS_FAILED'

// LOCATIONS
export const PARSE_LOCATIONS = 'PARSE_LOCATIONS'
export const PARSING_LOCATIONS = 'PARSING_LOCATIONS'
export const PARSED_LOCATIONS = 'PARSED_LOCATIONS'
export const PARSING_LOCATIONS_FAILED = 'PARSING_LOCATIONS_FAILED'

// MATERIALS
export const PARSE_MATERIALS = 'PARSE_MATERIALS'
export const PARSING_MATERIALS = 'PARSING_MATERIALS'
export const PARSED_MATERIALS = 'PARSED_MATERIALS'
export const PARSING_MATERIALS_FAILED = 'PARSING_MATERIALS_FAILED'

// TOOLS
export const PARSE_TOOLS = 'PARSE_TOOLS'
export const PARSING_TOOLS = 'PARSING_TOOLS'
export const PARSED_TOOLS = 'PARSED_TOOLS'
export const PARSING_TOOLS_FAILED = 'PARSING_TOOLS_FAILED'

// MACHINES
export const PARSE_MACHINES = 'PARSE_MACHINES'
export const PARSING_MACHINES = 'PARSING_MACHINES'
export const PARSED_MACHINES = 'PARSED_MACHINES'
export const PARSING_MACHINES_FAILED = 'PARSING_MACHINES_FAILED'

// ACTIONS
export const PARSE_ACTIONS = 'PARSE_ACTIONS'
export const PARSING_ACTIONS = 'PARSING_ACTIONS'
export const PARSED_ACTIONS = 'PARSED_ACTIONS'
export const PARSING_ACTIONS_FAILED = 'PARSING_ACTIONS_FAILED'

// ANNOTATIONS
export const PARSE_ANNOTATIONS = 'PARSE_ANNOTATIONS'
export const PARSING_ANNOTATIONS = 'PARSING_ANNOTATIONS'
export const PARSED_ANNOTATIONS = 'PARSED_ANNOTATIONS'
export const PARSING_ANNOTATIONS_FAILED = 'PARSING_ANNOTATIONS_FAILED'

// MEDIAS
export const PARSE_MEDIAS = 'PARSE_MEDIAS'
export const PARSING_MEDIAS = 'PARSING_MEDIAS'
export const PARSED_MEDIAS = 'PARSED_MEDIAS'
export const PARSING_MEDIAS_FAILED = 'PARSING_MEDIAS_FAILED'

// PROJECTS
export const PARSE_PROJECTS = 'PARSE_PROJECTS'
export const PARSING_PROJECTS = 'PARSING_PROJECTS'
export const PARSED_PROJECTS = 'PARSED_PROJECTS'
export const PARSING_PROJECTS_FAILED = 'PARSING_PROJECTS_FAILED'

// BIBLIOGRAPHY
export const PARSE_BIBLIOGRAPHY = 'PARSE_BIBLIOGRAPHY'
export const PARSING_BIBLIOGRAPHY = 'PARSING_BIBLIOGRAPHY'
export const PARSED_BIBLIOGRAPHY = 'PARSED_BIBLIOGRAPHY'
export const PARSING_BIBLIOGRAPHY_FAILED = 'PARSING_BIBLIOGRAPHY_FAILED'

// TIMELINES
export const PARSE_TIMELINES = 'PARSE_TIMELINES'
export const PARSING_TIMELINES = 'PARSING_TIMELINES'
export const PARSED_TIMELINES = 'PARSED_TIMELINES'
export const PARSING_TIMELINES_FAILED = 'PARSING_TIMELINES_FAILED'