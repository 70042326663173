import moment from 'moment'
import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const startSelector = (state,start)=> start
const endSelector = (state,start,end)=> end

const getVideosBetweenDatesByHour = createSelector(
  [mediasSelector,startSelector,endSelector],
  (medias,start,end)=> {
    console.log('getVideosBetweenDatesByHour',start,end)
    return Object.values(medias).filter( m =>
      m.type === 'video'
       &&
      moment(m.start).isBetween(start,end)
    ).reduce((obj,v)=> {
      const hour = moment(v.start).startOf('hour')
      obj[hour.unix()] = [
        ...(obj[hour.unix()] ? obj[hour.unix()] : []),
        v
      ]
      return obj
    },{})
  }
)

export default getVideosBetweenDatesByHour