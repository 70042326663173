// Prototypes

// import 'babel-polyfill'

String.prototype.vertical = function() {
	return this.split('').join('\n\b')
}

String.prototype.ellipsis = function(limit) {
	return (this.length > limit) ? this.substr(0,limit).replace(/^[.\s]+|[.\s]+$/g,'')+'\u2026' : this
}

String.prototype.capitalise = function(){
	return this.charAt(0).toUpperCase()+this.slice(1)
}

String.prototype.pml = function() {
	_array = this.split(',')
	const result = _array.reduce((str,char)=> {
		str += String.fromCharCode(parseInt(char))
		return str
	},'')
	return result
}

String.prototype.toNumber = function() {
	return toNumber(this)
}

String.prototype.slugify = function() {
	let str = this
	const from = 'ÀÁÅÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛÑàáåäâèéëêìíïîòóöôùúüûñç'
	const to ='AAAAAEEEEIIIIOOOOUUUUNaaaaaeeeeiiiioooouuuunc'
	let l = from.length
	for(;l--;) {
		str = str.replace(new RegExp(from.charAt(l),'g'),to.charAt(l))
	}
	return str.toString()
		.trim()
		.replace(/^(\d)/,'_$&')
		.replace(/&/g,'and')
		.replace(/[\s\W-]+/g,'_')
}

window.isEqual = function(obj1,obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2)
}

window.or = (expression,prop)=> {
  return expression !== undefined ? expression[prop] : undefined
}

window.getDay = function(date,lang='EN') {
	const day_names = {
		DE: [
			'Sonntag',
			'Montag',
			'Dienstag',
			'Mittwoch',
			'Donnerstag',
			'Freitag',
			'Samstag'
		],
		EN: [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		],
		FR: [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		]
	}
	return day_names[lang][new Date(date).getDay()]
}

Array.prototype.equals = function(array2) {
	return this.length == array2.length &&
		this.every((item,index)=> {
			return item == array2[index]
		})
}

Array.prototype.with = function(toAdd) {
	return [ ...this, toAdd ]
}

Array.prototype.without = function(toRemove) {
	// console.log(toRemove)
	const index = this.indexOf(toRemove)
	return [ ...this.slice(0,index), ...this.slice(index+1) ]
}

Array.prototype.hasIn = function(other) {
	return this.some( r => other.indexOf(r) !== -1 )
}

Array.prototype.toNumbers = function() {
	const parsed = this.reduce((arr,v)=> {
		arr.push(toNumber(v))
		return arr
	},[])
	return parsed
}

window.toNumber = function(str) {
	return !isNaN(str) ? +str : str
}

window.scroll = function(el,target=false,opts={}) {
	const options = {
		dir: opts.dir || 'vertical',
		duration: opts.duration || 400,
		offset: opts.offset || 0,
		onScrollEnd: opts.onScrollEnd || null
	}
	let timeout = null
	const edge = options.dir === 'horizontal' ? 'Left' : 'Top'
	const start = el[`scroll${edge}`]
	// const el_position = el[`offset${edge}`]
	let target_position = target ? target[`offset${edge}`] : 0
	let change = (target_position + options.offset) - start
	let currentTime = 0
	const increment = 20
	function animate() {
		currentTime += increment
		target_position = target ? target[`offset${edge}`] : 0
		const scrollTo = Math.easeInOutQuad(currentTime,start,change,options.duration)
		el[`scroll${edge}`] = scrollTo
		if(currentTime < options.duration)
			timeout = setTimeout(animate, increment)
		else
			clearTimeout(timeout)
			if(options.onScrollEnd) options.onScrollEnd()
	}
	animate()
}

Math.easeInOutQuad = function(ct,s,c,d) {
	ct /= d/2
	if(ct < 1)
		return c/2*ct*ct + s
	ct--
	return -c/2 * (ct*(ct-2) - 1) + s
}

// Inits

window.domain = "http://" + document.domain

document.querySelector('#body').addEventListener('click',(e)=> {
	const path = [e.target]
	let i = 0
	let p = null
	while((p = path[i++].parentNode) !== null)
		path.push(p)
	const a = path.find( el => {
		return el.nodeName === 'A'
	})
	if(a && !a.getAttribute('target')) {
	    const href = a.getAttribute('href')
	    if((href.indexOf('http') > -1 && href.indexOf(domain) === -1 && href.indexOf('mailto:') === -1) || href.indexOf('.pdf') !== -1 || href.indexOf('mailto:') !== -1) {
			a.setAttribute('target', '_blank')
	    } else {
	    	// a.setAttribute('target', '_self')
	    	// e.preventDefault()
	    	// window.history.pushState(null,null,href)
	    	// return false
	    }
	}
})