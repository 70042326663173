import unionBy from 'lodash/unionBy'
import { createSelector } from 'reselect'

import {
  ANNOTATIONS
} from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const idsSelector = (state,object_ids,)=> object_ids
const typeSelector = (state,object_ids,type)=> type
const listSelector = (state,object_ids,type)=> state[type].list

const getObjectsByOverlapByType = createSelector(
  [annotationsSelector,idsSelector,typeSelector,listSelector],
  (annotations,object_ids,type,list)=> {
    console.log('getObjectsByOverlapByType')
    return Object.values(annotations)
    .filter( a =>
      (a.shows ? a.shows : []).find( b => (object_ids && object_ids.length > 0) ? object_ids.indexOf(b.id) !== -1 : b )
    )
    .reduce((arr,a)=> {
      return unionBy(arr,a.shows.filter(({ type })=> type === type ),'id')
    },[])
    .map(({ id })=> list[id] )
    .filter( obj => obj )
})

export default getObjectsByOverlapByType

export const createGetObjectsByOverlapByType = ()=> getObjectsByOverlapByType