import React, { createContext, useState } from 'react'

export const ResidencyContext = createContext()

export const ResidencyProvider = ({ children })=> {

  const [ residency_id, setResidency_id ] = useState(false)
  const [ start, setStart ] = useState(false)
  const [ end, setEnd ] = useState(false)
  const [ duration, setDuration ] = useState(false)

  const residencyContext = {
    start,
    setStart,
    end,
    setEnd,
    duration,
    setDuration
  }

  return <ResidencyContext.Provider
      value={ residencyContext }
    >
      { children }
    </ResidencyContext.Provider>
}