import moment from 'moment'
import React, { useEffect, useLayoutEffect, createContext, useState, useCallback, useContext } from 'react'
import { history } from '|/store'
import { useInterval } from '|/hooks'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'

import useVideo from './useVideo'
import Video from './Video'

import styles from './main.styl'

const PlayerContext = createContext()

const PlayerProvider = ({ children })=> {

  const pathState = usePathState()

  const [ autoplay, setAutoplay ] = useState(false)
  const [ videos, setVideos ] = useState([])
  const [ _status, _setStatus ] = useState({})
  const [ timestamp, setTimestamp ] = useState(undefined)
  const [ currentTime, setCurrentTime ] = useState(0)
  const [ _wanna, _setWanna ] = useState(false)
  const [ playing, setPlaying ] = useState(false)
  const [ ready, setReady ] = useState(false)
  const [ unmuted, setUnmuted ] = useState(undefined)
  const [ timerDelay, setTimerDelay ] = useState(1000)

  const init = (vids,ts)=> {
    console.log('INIT',ts)
    setVideos(vids)
    setCurrentTime(ts)
    setReady(false)
    console.log('VIDEOS',vids)
    _setStatus(vids.reduce((o,v)=> {
      if(_status[v.id])
        o[v.id] = _status[v.id]
      else
        o[v.id] = false
      return o
    },{}))
    setAutoplay(true)
  }

  const setStatus = (video_id,status)=> {
    // console.log('setting status',video_id,status)
    _setStatus({..._status,[video_id]:status})
  }

  const play = ()=> {
    _setWanna(true)
    if(ready) {
      console.log('PLAY!')
      setPlaying(true)
      // setTimerDelay(1000)
      _setWanna(false)
      setAutoplay(false)
    }
  }

  const pause = ()=> {
    setPlaying(false)
    // setTimerDelay(null)
  }

  const ping = (vids,ts)=> {
    if(ts !== currentTime || !isEqual(vids,videos)) {
      if(playing) {
        _setWanna(true)
      }
      pause()
      setCurrentTime(ts)
      init(vids,ts)
    }
  }

  const jumpTo = (ts)=> {
    const timestamp = moment.isMoment(ts) ? ts.unix() : ts
    const to = {
      left: {
        s: 'min'
      },
      right: {
        s: 'min'
      },
      center: {
        play: timestamp
      }
    }
    console.log(to)
    history.push(pathState.toString(pathState.merge(to)))
  }

  useInterval(()=> {
    console.log('interval running',currentTime+1)
    setCurrentTime(currentTime+1)
  }, playing ? timerDelay : null )

  useLayoutEffect(()=> {
    console.log('videos',videos)
    console.log('status',_status)
    setReady(videos.length > 0 && Object.values(_status).length > 0 && Object.values(_status).every( v => v === true ))
  },[ _status ])

  useLayoutEffect(()=> {
    // console.log('status',_status)
    // console.log('ready:',ready)
    // console.log('wanna',_wanna)
    // console.log('autoplay',autoplay)
    if(ready) {
      setUnmuted(videos[0].id)
      console.log('UNMUTE',videos[0].id)
      if(autoplay || _wanna) {
        play()
      }
    } else {
      // pause()
    }
  },[ ready ])

  useLayoutEffect(()=> {
    // if(playing && currentTime) {
    //   const to = {
    //     center: {
    //       play: currentTime
    //     }
    //   }
    //   history.replace(pathState.toString(pathState.merge(to)))
    // }
  },[ currentTime ])

  const playerContext = {
    init,
    currentTime,
    unmuted,
    setTimestamp,
    videos,
    setVideos,
    setStatus,
    playing,
    play,
    pause,
    ping,
    jumpTo,
  }

  return <PlayerContext.Provider
      value={ playerContext }
    >
      { children }
    </PlayerContext.Provider>
}

const usePlayer = ()=> useContext(PlayerContext)

const Player = ({ timestamp, videos })=> {

  const player = usePlayer()

  useEffect(()=> {
    console.log('init')
    player.init(videos,timestamp.toNumber())
  },[])

  useLayoutEffect(()=> {
    player.ping(videos,timestamp.toNumber())
  },[ timestamp, videos ])

  // useEffect(()=> {
  //   player.play(true)
  // },[ player.ready ])

  return (
    <div className={ styles.container }>
      { videos.map( video =>
        <div key={ video.id } className={ styles.video }>
          <Video
            timestamp={ timestamp }
            object={ video }
          />
        </div>
      )}
    </div>
  )
}

export {
  PlayerContext,
  PlayerProvider,
  useVideo,
  usePlayer,
}

export default Player