import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_TIMELINES,
  PARSING_TIMELINES,
  PARSED_TIMELINES,
  PARSING_TIMELINES_FAILED
} from '|/store/constants'

import {
  RESIDENCIES,
  LIST_VALUES,
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const TYPES = lib.Namespace('http://zhdk.ch/hands_on/types/')
  const residencies = lib.Namespace(RESIDENCIES)
  const rdfs = lib.Namespace('http://www.w3.org/2000/01/rdf-schema#')
  const skos = lib.Namespace('http://www.w3.org/2004/02/skos/core#')
  store.each(null,null,TYPES('HO-RESIDENCY')).map((r)=> {
    // console.log(r.value)
    const id = r.value.replace(RESIDENCIES,'')
    data[id] = {
      id,
      ns: RESIDENCIES,
      description: or(store.any(
          r,
          residencies('about')
        ),'value'),
      name: or(store.any(
          r,
          residencies('name')
        ),'value'),
      start: or(store.any(
          r,
          residencies('date-start')
        ),'value'),
      end: or(store.any(
          r,
          residencies('date-end')
        ),'value'),
    }
    store.each(
      r
    )
    .map((key)=> (
      {
        key,
        position: or(store.any(
          key,
          skos('position')
        ),'value')
      }
    ))
    .sort((a,b)=> a.position - b.position )
    .map(({key})=> {
      const label = or(store.any(
        key,
        rdfs('label')
      ),'value')
      data[id][label] = store.each(
        r,
        key,
      ).map( r => {
        if(r.termType === 'NamedNode') {
          if(r.value.indexOf(LIST_VALUES) !== -1)
            return or(store.any(
              r,
              rdfs('label')
            ),'value')
          return {
            uri: r.value
          }
        }
        if(r.value)
          return r.value
      })
    })
  })
  return data
}

function* parseTimelines() {
  yield put({
    type: PARSING_TIMELINES
  })
  const timelines = yield call(match)
  try {
    yield put({
      type: PARSED_TIMELINES,
      data: timelines
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_TIMELINES_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseTimelines),
  ])
}