import { createSelector } from 'reselect'

import { ANNOTATIONS } from '|/settings'

const stateSelector = state => state
const annotationsSelector = state => state[ANNOTATIONS].list
const idsSelector = (state,annotation_ids)=> annotation_ids

const getObjectsWithAnnotationByTypeByAnnotations = (state,annotations,annotation_ids)=> {
  // console.log('getObjectsWithAnnotationByTypeByAnnotations',annotation_ids)
  return Object.values(annotations).filter( a => annotation_ids.indexOf(a.id) !== -1 )
    .reduce((arr,a)=> {
      arr = [
        ...arr,
        ...a.shows.reduce((arr,s)=> ( arr.push({...s,annotation_id:a.id}), arr),[])
      ]
      return arr
    },[])
    .reduce((obj,o)=> {
      obj[o.type] = [
        ...(obj[o.type] ? obj[o.type] : []),
        o
      ]
      return obj
    },{})
}

export default createSelector(
  [stateSelector,annotationsSelector,idsSelector],
  getObjectsWithAnnotationByTypeByAnnotations
)