import moment from 'moment'
import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_ANNOTATIONS,
  PARSING_ANNOTATIONS,
  PARSED_ANNOTATIONS,
  PARSING_ANNOTATIONS_FAILED
} from '|/store/constants'

import {
  ANNOTATIONS
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const TYPES = lib.Namespace('http://zhdk.ch/hands_on/types/')
  const annotations = lib.Namespace(ANNOTATIONS)
  const RDF = lib.Namespace('http://www.w3.org/1999/02/22-rdf-syntax-ns#')
  const RDFS = lib.Namespace('http://www.w3.org/2000/01/rdf-schema#')

  store.each(null,null,TYPES('HO_ANNOTATION')).map((r)=> {
    // console.log(r.value)
    const id = r.value.replace(ANNOTATIONS,'')
    const d = {
      id,
      ns: ANNOTATIONS,
      shows: store.each(
          r,
          HO('shows'),
        ).map( s => {
          // console.log(s)
          // const sparqlQuery = `select ?uri_media
          //                       where {
          //                         ?uri_media ?type ?type_media .
          //                         ?uri_media ?annotated_with ?uri_annotation .
          //                         ?uri_media ?media_type ?image .
          //                         ?uri_annotation ?shows ?uri_object .
          //                         ?uri_object ?has_r ?r .
          //                       }`
          // const sparqlQuery = `SELECT ?s ?p ?o WHERE { ?s ?p ?o . }`
          // const query = lib.SPARQLToQuery(sparqlQuery,false,store)
          // const media = store.querySync(query)

          // BREAK
          
          // let preview_url = undefined
          // let media_obj = undefined
          // store.each(
          //     null,
          //     HO('shows'),
          //     s,
          //   ).map( r => {
          //     media_obj = store.each(
          //         null,
          //         HO('annotated_with'),
          //         r,  
          //       ).find( a => {
          //         preview_url = or(store.any(
          //             a,
          //             HO('preview_url')
          //           ),'value')
          //         const media_type = or(store.any(
          //             a,
          //             HO('media_type')
          //           ),'value')
          //         return media_type === 'image' && preview_url
          //       })
          //       // .map( c => {
          //       //   return c.preview_url
          //       // })
          //   })
          return {
            uri: s.value,
            id: s.value.split('/').pop(),
            type: s.value.split('/').slice(0,-1).join('/')+'/',
            // media: media
            // ...( media_obj ? { preview_url } : {} )
          }
        }),
      relative_start: or(store.any(
          r,
          HO('location_start')
        ),'value'),
      duration: or(store.any(
          r,
          HO('location_duration')
        ),'value'),
      sources: store.each(
          undefined,
          HO('annotated_with'),
          r,
        ).map( a => {
          return {
            id: a.value.split('/').pop(),
            start: or(store.any(
                a,
                HO('start')
              ),'value'),
            duration: or(store.any(
                a,
                HO('duration')
              ),'value'),
          }
        }),
    }
    if(d.relative_start && d.sources[0]) {
      const s = d.sources[0]
      d.relative_start = '00:'+d.relative_start
      d.duration = '00:'+d.duration
      d.start = moment(s.start).add(moment.duration(d.relative_start)).format()
      d.end = moment(d.start).add(moment.duration(d.duration)).format()
    }
    delete d.sources
    data[id] = d
  })
  return data
}

function* parseAnnotations() {
  yield put({
    type: PARSING_ANNOTATIONS,
  })
  const annotations = yield call(match)
  try {
    yield put({
      type: PARSED_ANNOTATIONS,
      data: annotations
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_ANNOTATIONS_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseAnnotations),
  ])
}