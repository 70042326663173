import unionBy from 'lodash/unionBy'
import { createSelector } from 'reselect'

import {
  ACTIONS,
  ANNOTATIONS,
} from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const idsSelector = (state,annotation_ids)=> annotation_ids
const typesSelector = (state,annotation_ids,types)=> types
const listsSelector = (state,annotation_ids,types)=> types.reduce((obj,t)=> ({ ...obj, [t]: state[t].list }),{})

const getObjectsWithAnnotationByAnnotationsByTypes = createSelector(
  [annotationsSelector,idsSelector,typesSelector,listsSelector],
  (annotations,annotation_ids,types,lists)=> {
    console.log('getObjectsWithAnnotationByAnnotationsByTypes',annotation_ids)
    // return Object.values(annotations).filter( a => annotation_ids.indexOf(a.id) !== -1 )
    //   .reduce((arr,a)=> {
    //     arr = [
    //       ...arr,
    //       ...a.shows.reduce((arr,s)=> ( arr.push({...s,annotation_id:a.id}), arr),[])
    //     ]
    //     return arr
    //   },[])
    //   .reduce((obj,o)=> {
    //     obj[o.type] = [
    //       ...(obj[o.type] ? obj[o.type] : []),
    //       o
    //     ]
    //     return obj
    //   },{})

    const records = annotation_ids.map( id => annotations[id] )
      .reduce((arr,a)=> {
        return unionBy(arr,a.shows.reduce((arr,r)=> {
          return [
            ...arr,
            ...(
              [
                {
                  ...r,
                  annotation_id: a.id,
                  duration: a.duration,
                }
              ]),
          ]
          console.log(shows)
          return shows
        },[]),'id')
      },[])
    return types.reduce((obj,t)=> {
      return {
        ...obj,
        [t]: records.filter((r)=> r.type === t ).map((r)=> ({ ...r, ...lists[t][r.id] }) ),
      }
    },{})
  }
)

export default getObjectsWithAnnotationByAnnotationsByTypes

export const createGetObjectsWithAnnotationByAnnotationsByTypes = ()=> getObjectsWithAnnotationByAnnotationsByTypes