import { createSelector } from 'reselect'

const annotationsSelector = state => state.annotations.list
const keySelector = (state,key)=> key

const getAnnotations = (annotations,key)=> {
  console.log('getAnnotations')
  return Object.values(annotations)
}

export default createSelector(
  [annotationsSelector,keySelector],
  getAnnotations
)