import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_ACTORS,
  PARSING_ACTORS,
  PARSED_ACTORS,
  PARSING_ACTORS_FAILED
} from '|/store/constants'

import {
  ACTORS,
  BIBLIOGRAPHY,
  LIST_VALUES,
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const types = lib.Namespace('http://zhdk.ch/hands_on/types/')
  const actors = lib.Namespace(ACTORS)
  const rdfs = lib.Namespace('http://www.w3.org/2000/01/rdf-schema#')
  const skos = lib.Namespace('http://www.w3.org/2004/02/skos/core#')

  store.each(undefined,undefined,types('HO-AKTEUR')).map((r)=> {
    const id = r.value.replace(ACTORS,'')
    const d = data[id] = {
      id,
      ns: ACTORS,
      preview_urls: store.each(
          r,
          HO('has_media'),
        ).map( a => {
          return or(store.any(
            a,
            HO('preview_url')
          ),'value')
        }).filter( b => b ),
    }
    store.each(
      r
    )
    .map((key)=> (
      {
        key,
        position: or(store.any(
          key,
          skos('position')
        ),'value')
      }
    ))
    .sort((a,b)=> a.position - b.position )
    .map(({key})=> {
      const label = or(store.any(
        key,
        rdfs('label')
      ),'value')
      data[id][label] = store.each(
        r,
        key,
      ).map( r => {
        if(r.termType === 'NamedNode') {
          if(r.value.indexOf(LIST_VALUES) !== -1)
            return or(store.any(
              r,
              rdfs('label')
            ),'value')
          return {
            uri: r.value
          }
        }
        if(r.value)
          return r.value
      })
    })
    data[id].label = d['Vorname'] && d['Name(n)'] ?
        `${d['Vorname']} ${d['Name(n)']}`
      :
        d['Firma/Institution'] || d['Kürzel']
  })
  return data
}

function* parseActors() {
  yield put({
    type: PARSING_ACTORS,
  })
  const actors = yield call(match)
  try {
    yield put({
      type: PARSED_ACTORS,
      data: actors
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_ACTORS_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseActors),
  ])
}