import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'

import { createGetActionsByGroups } from '|/store/Actions/selectors/getActionsByGroups'

import Action from './Action'

import styles from './main.styl'

const Actions = ({ actionIds, actionGroupIds })=> {

  const [ group_ids, setGroup_ids ] = useState([])
  const getActionsByGroups = useMemo(createGetActionsByGroups,[group_ids])
  const actions = useSelector( state => getActionsByGroups(state,group_ids) )
  const [ selected_ids, setSelected_ids ] = useState([])

  useEffect(()=> {
    const id_array = Array.isArray(actionIds) ? actionIds : [actionIds]
    const new_selected_ids = actions.reduce((arr,{ id })=> {
      if(id_array.indexOf(id) !== -1)
        arr.push(id)
        return arr
    },[])
    if(!isEqual(new_selected_ids,selected_ids)) {
      setSelected_ids(new_selected_ids)
    }
  }, [ actionIds, actions ])

  useEffect(()=> {
    if(!isEqual(group_ids,actionGroupIds)) {
      console.log(group_ids)
      console.log(actionGroupIds)
      setGroup_ids(actionGroupIds)
    }
  },[ actionGroupIds ])

  return (
    <div className={ styles.actions }>
      { actions.map((action)=> {
        const selected = selected_ids && selected_ids.indexOf(action.id) !== -1
        return <Action
          key={ action.id }
          selected={ selected }
          action={ action }
        />
      })}
    </div>
  )
}

export default Actions