import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as PathStateProvider, usePathState } from '|/hooks/pathState'
import { ConnectedRouter } from 'connected-react-router'
import { Switch, Route, Redirect, Router } from 'react-router'

import store, { history, persistor } from './store'
import sagas from './store/sagas'

import App from './containers/App'

const init = {
  residency_id: 0,
  left: {
    s: 'max',
    c: 'actions',
  },
  right: {
    s: 'max',
    z: 0.01,
  },
  center: {
    
  }
}

store.runSagas(sagas)

export default props => {

  const pathState = usePathState()

  const indexRoute = pathState.toString(init)

  // return <Provider store={store}>
  //   <ConnectedRouter history={history}>
  //     <PathStateProvider init={ init }>
  //       <Switch>
  //         <Route exact path="/">
  //           <Redirect to={ indexRoute }/>
  //         </Route>
  //         <Route>
  //           <App />
  //         </Route>
  //       </Switch>
  //     </PathStateProvider>
  //   </ConnectedRouter>
  // </Provider>

  return <Provider store={store}>
    <PersistGate persistor={ persistor }>
      <Router history={history}>
        <PathStateProvider init={ init }>
          <Switch>
            <Route exact path="/">
              <Redirect to={ indexRoute }/>
            </Route>
            <Route>
              <App />
            </Route>
          </Switch>
        </PathStateProvider>
      </Router>
    </PersistGate>
  </Provider>
}