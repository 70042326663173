import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Switch, Route, NavLink } from 'react-router-dom';

import Tooltip from 'react-tooltip'

import { useLocation } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'

import { ZoomProvider } from '|/contexts/Zoom'
import { PlayerProvider } from '|/components/Player'
import { HighlightProvider } from '|/contexts/Highlight'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'

import styles from './main.styl';

import Panel from '|/components/Panel'
import Burger from '|/containers/Burger';
import Context from '|/containers/Context';
import Residencies from '|/containers/Residencies';

import Window from '|/containers/Window'
import Zoom from '|/containers/Zoom'
import Info from '|/containers/Info'

import {
  PARSE_TURTLE
} from '|/store/constants'

const App = props => {

  const dispatch = useDispatch()
  const [ refreshing, setRefreshing ] = useState(false)

  // dispatch({
  //   type: PARSE_TURTLE,
  //   callback: ()=> setRefreshing(false)
  // })

  return (
    <div
      id="App"
      className={ styles.container }
    >
      <div className={ styles.header }>
        <h1>Hands-on</h1>
      </div>
      <main>
        <PlayerProvider>
          <HighlightProvider>
            <Switch>
              <Route path="/:residency_id/s::left_status;c::context/s::right_status;z::zoom_level/">
                <Panel side="left">
                  <Context/>
                </Panel>
                <Panel side="right">
                  <ZoomProvider>
                    <Residencies/>
                  </ZoomProvider>
                </Panel>
                <Route path="/:residency_id/s::left_status;c::context/s::right_status;z::zoom_level/play::timestamp">
                  <Window/>
                </Route>
              </Route>
            </Switch>
            <Zoom/>
            <Info/>
          </HighlightProvider>
        </PlayerProvider>
      </main>
      <Tooltip />
      <Burger />
    </div>
  );
}

export default App