import { createSelector } from 'reselect'

import { ACTIONS } from '|/settings'

const actionsSelector = state => state[ACTIONS].list

const getActionGroups = (actions,key)=> {
  console.log('getActionGroups')
  const results = Object.values(actions)
    .filter( a => a.is_group )
    .sort((a,b)=> {
        if(a.position < b.position)
          return -1
        if(a.position > b.position)
          return 1
        return 0
      })
  console.log(results)
  return results
}

export default createSelector(
  [actionsSelector],
  getActionGroups
)