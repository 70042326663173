import React from 'react'

import { useHighlight } from '|/contexts/Highlight'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const ActionGroup = ({ actionGroup, selected })=> {

  const { highlighted, highlight, lowlight } = useHighlight()

  return (
    <div
      data-position={ actionGroup.position }
      data-action-groups={ actionGroup.id+';' }
      className={[
        styles.action,
        selected ? styles.selected : undefined,
        // actionGroup.has_annotations ? styles.enabled : undefined,
        styles.enabled,
      ].join(' ') }
    >
      <Plink
        seo={ false }
        key={ actionGroup.id }
        to={{
          search: {
            toggle: {
              action_group_ids: actionGroup.id
            }
          }
        }}
        onMouseEnter={ e => highlight(`[data-action-groups*="${actionGroup.id};"]`,'highlighted') }
        onMouseLeave={ e => lowlight(`[data-action-groups*="${actionGroup.id};"]`,'highlighted') }
      >
        <div className={ styles.label }>{ `${actionGroup.label}` }</div>
      </Plink>
    </div>
  )
}

export default ActionGroup