import { createSelector } from 'reselect'

import { ANNOTATIONS } from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const rUriSelector = (state,rUri)=> rUri
const aUriSelector = (state,rUri,aUri)=> aUri

const getPrevAnnotationWithObject = createSelector(
  [annotationsSelector,rUriSelector,aUriSelector],
  (annotations,rUri,aUri)=> {
    console.log('getPrevAnnotationWithObject')
    const valid_annotations = Object.values(annotations)
      .filter( a => {
        const shows_ids = a.shows.reduce((arr,s)=> ( arr.push(s.id), arr ),[])
        return a.start && shows_ids.indexOf(rUri) !== -1
      })
      .sort((a,b)=> new Date(a.start) - new Date(b.start))
    return valid_annotations[valid_annotations.indexOf(annotations[aUri])-1]
  }
)

export default getPrevAnnotationWithObject

export const createGetPrevAnnotationWithObject = ()=> getPrevAnnotationWithObject