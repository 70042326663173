import uniq from 'lodash/uniq'
import moment from 'moment'
import React, { useState, useEffect, useLayoutEffect, useContext, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { history } from '|/store'
import { usePathState } from '|/hooks/pathState'
import { createGetAnnotationsByIds } from '|/store/Annotations/selectors/getAnnotationsByIds'

import { ZoomContext } from '|/contexts/Zoom'
import { usePlayer } from '|/components/Player'

import Video from './Video'
import Annotations from './Annotations'

import styles from './main.styl'

const Hour = ({ timestamp, videos })=> {

  const playheadIndicator = useRef(null)
  const pathState = usePathState()
  const { zoom } = useContext(ZoomContext)
  const { jumpTo, currentTime, playing } = usePlayer()
  // const = usePlayer()
  const [ label, setLabel ] = useState('')
  const [ annotation_ids, setAnnotation_ids ] = useState([])
  const [ hour, setHour ] = useState(null)
  const getAnnotationsByIds = useMemo(createGetAnnotationsByIds,[annotation_ids])
  const annotations = useSelector( state => getAnnotationsByIds(state,annotation_ids) )

  const _onHourClick = (e)=> {
    const mouse_y = e.clientY
    const bounds = e.currentTarget.getBoundingClientRect()
    const target_top = bounds.top
    const target_height = bounds.height
    const relative_y = mouse_y - target_top
    const factor = relative_y / target_height
    const time = hour.clone().add(3600*factor,'seconds')

    // console.log(time)

    jumpTo(time)
    // const timestamp = time.unix()
    // const to = {
    //   left: {
    //     s: 'min'
    //   },
    //   right: {
    //     s: 'min'
    //   },
    //   center: {
    //     play: timestamp
    //   }
    // }
    // history.push(pathState.toString(pathState.merge(to)))
  }

  useEffect(()=> {
    console.log('BOOM!')
    const hour = moment.unix(timestamp)
    const label = hour.format('HH:mm')
    setHour(hour)
    setLabel(label)
  },[ timestamp ])

  useEffect(()=> {
    if(videos) {
      // console.log('BÄM!')
      const ids = videos.reduce((arr,v)=> {
        if(v.annotation_ids)
          arr = [...arr,...v.annotation_ids]
        // merge(v.annotation_ids,arr,isEqual)
        return arr
      },[])
      setAnnotation_ids(uniq(ids))
    }
  },[ videos ])

  useLayoutEffect(()=> {
    if(hour && playheadIndicator.current && playing) {
      const playhead = moment.unix(currentTime)
      if(playhead.isBetween(hour,hour.clone().endOf('hour'))) {
        const relative_currentTime = moment.duration(playhead.diff(hour)).asSeconds()
        playheadIndicator.current.style.display = 'block'
        playheadIndicator.current.style.top = `${relative_currentTime/3600*100}%`
      } else {
        playheadIndicator.current.style.display = 'none'
      }
    }
    if(playing) {
      playheadIndicator.current.style.borderTopStyle = 'solid'
    } else {
      playheadIndicator.current.style.borderTopStyle = 'dotted'
    }
  },[ currentTime, playing ])

  const cachedAnnotations = useMemo(()=>
    <Annotations annotations={ annotations } hour={ hour } />
  ,[annotation_ids,hour])

  return (
    <div
      className={ styles.hour }
      onClick={ _onHourClick }
      style={{
        // height: `${3600 * zoom}vh`
      }}
    >
      <div
        ref={ playheadIndicator }
        className={ styles.playheadIndicator }
      />
      <div className={ styles.label }>
        { label }
      </div>
      { videos.map((video)=>
        <Video key={ video.id } video={ video } hour={ hour } />
      )}
      { cachedAnnotations }
    </div>
  )
}

Hour.defaultProps = {
  videos: PropTypes.object
}

export default Hour