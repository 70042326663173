import moment from 'moment'
import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const startSelector = (state,start)=> start
const endSelector = (state,start,end)=> end

const getVideosBetweenDates = (medias,start,end)=> {
  console.log('getVideosBetweenDates',start,end)
  return Object.values(medias).filter( m =>
    m.type === 'video'
     &&
    moment(m.start).isBetween(start,end)
  )
}

export default createSelector(
  [mediasSelector,startSelector,endSelector],
  getVideosBetweenDates
)