import React, { Fragment, useMemo, useState, useRef, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'

import { getObjectByType } from '|/store/Timelines/selectors'
import { useQuery } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'

import Overlay from '|/helpers/Overlay'
import Img from '|/helpers/Img'

import styles from './main.styl'

const infos = {
  impressum: `Impressum

Dieses User Interface ist Teil des Forschungsprojekts «Hands-on. Dokumentation künstlerisch-technischer Prozesse im Druck». Das Projekt wird 2018–2021 vom Institute for Contemporary Art Research der Zürcher Hochschule der Künste durchgeführt und vom Schweizerischen Nationalfonds gefördert.

Projektteam: Christoph Schenker, Projektleiter; Michael Günzburger, künstlerisch-wissenschaftlicher Mitarbeiter; Mara Züst, kunstwissenschaftliche Mitarbeiterin; Kris Decker, Wissenschaftsforscher; Piet Esch, Kamera; Almira Medaric, studentische Mitarbeiterin.

Projektpartner/innen: Thomi Wolfensberger, Drucker, Steindruckerei Wolfensberger AG, Zürich; Dorothea Spitza, Restauratorin, Hochschule der Künste Bern; Joachim Sieber, Kunsthistoriker, Zürich; Patrick Wagner, Drucker, Stockholm; Schweizerisches Institut für Kunstwissenschaft (SIK-ISEA), Zürich; Graphische Sammlung ETH Zürich; Archiv sowie Medien- und Informationszentrum der ZHdK; Verein Material-Archiv, Zürich; Institute for the Performing Arts und Film, ZHdK; Astrom / Zimmer, Design & Code, Zürich; Fabrice Tereszkiewicz, IT System Engineer, 51st Floor Studio, Zürich.`
}

const Zoom = props => {

  const { info } = useQuery()
  if(!info) return null
  const pathState = usePathState()

  const _onCloseClick = (e)=> {
    const to = {
      search: {
        remove: {
          info,
        }
      }
    }
    history.push(pathState.toString(pathState.merge(to)))
  }

  return (
    <Overlay
      className={ styles.zoom }
      onClose={ _onCloseClick }
    >
      <div className={ styles.info }>
        { infos[info] }
      </div>
    </Overlay>
  )
}

export default Zoom