import moment from 'moment'
import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_MEDIAS,
  PARSING_MEDIAS,
  PARSED_MEDIAS,
  PARSING_MEDIAS_FAILED
} from '|/store/constants'

import {
  MEDIAS
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const skos = lib.Namespace('http://www.w3.org/2004/02/skos/core#')

  // store.each(null,null,TYPES('HO-MEDIA')).map((id)=> {
  store.each(null,HO('media_type'),'video').map((r)=> {
    const id = r.value.replace(MEDIAS,'')
    data[id] = {
      id,
      ns: MEDIAS,
      type: or(store.any(
          r,
          HO('media_type')
        ),'value'),
      start: or(store.any(
          r,
          HO('start')
        ),'value'),
      duration: or(store.any(
          r,
          HO('duration')
        ),'value'),
      filename: or(store.any(
          r,
          HO('file_original_name')
        ),'value'),
      madek_entry_id: or(store.any(
          r,
          HO('entry_id')
        ),'value'),
      madek_entry_url: or(store.any(
          r,
          HO('entry_url')
        ),'value'),
      madek_asset_id: or(store.any(
          r,
          HO('file_id')
        ),'value'),
      madek_asset_url: or(store.any(
          r,
          HO('preview_url')
        ),'value'),
      annotation_ids: store.each(
          r,
          HO('annotated_with')
        ).map( a => a.value )
      }
    })

  // store.each(null,null,TYPES('HO-MEDIA')).map((id)=> {
  //   data[id.value] = {
  //     id: id.value,
  //     type: or(store.any(
  //         id,
  //         HO('media_type')
  //       ),'value'),
  //     start: or(store.any(
  //         id,
  //         HO('start')
  //       ),'value'),
  //     duration: or(store.any(
  //         id,
  //         HO('duration')
  //       ),'value'),
  //     filename: or(store.any(
  //         id,
  //         HO('file_original_name')
  //       ),'value'),
  //     madek_entry_id: or(store.any(
  //         id,
  //         HO('entry_id')
  //       ),'value'),
  //     madek_entry_url: or(store.any(
  //         id,
  //         HO('entry_url')
  //       ),'value'),
  //     madek_asset_id: or(store.any(
  //         id,
  //         HO('file_id')
  //       ),'value'),
  //     madek_asset_url: or(store.any(
  //         id,
  //         HO('file_url')
  //       ),'value'),
  //     annotations: store.each(
  //         id,
  //         HO('annotated_with')
  //       ).map( a => {
  //         return {
  //           id: a.value,
  //           relative_start: or(store.any(
  //               a,
  //               HO('location_start')
  //             ),'value'),
  //           duration: or(store.any(
  //               a,
  //               HO('location_duration')
  //             ),'value'),
  //           shows: store.each(
  //               a,
  //               HO('shows'),
  //             ).map( s => {
  //               return {
  //                 id: s.value,
  //                 type: or(store.any(
  //                     s,
  //                     RDF('type'),
  //                   ),'value'),
  //               }
  //             }),
  //         }
  //       }).sort((a,b)=> new Date(`1970-01-01T${a.start}0Z`).getTime() - new Date(`1970-01-01T${b.start}0Z`).getTime() )
  //     }
  //   })
  Object.values(data).map( d => {
    if(d.start) {
      d.end = moment(d.start).add(moment.duration(d.duration)).format()
      // d.annotations.map( a => {
      //   if(a.relative_start) {
      //     a.relative_start = '00:'+a.relative_start
      //     a.start = moment(d.start).add(moment.duration(a.relative_start)).format()
      //   }
      //   if(a.duration)
      //     a.duration = '00:'+a.duration
      // })
    }
  })
  return data
}

function* parseMedias() {
  yield put({
    type: PARSING_MEDIAS,
  })
  const medias = yield call(match)
  try {
    
    yield put({
      type: PARSED_MEDIAS,
      data: medias
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_MEDIAS_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseMedias),
  ])
}