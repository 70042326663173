import { createSelector } from 'reselect'

const stateSelector = (state)=> state
const listSelector = (state,type)=> state[type].list
const uriSelector = (state,type,uri)=> uri

const getPaddedObjectByType = createSelector(
  [stateSelector,listSelector,uriSelector],
  (state,list,uri)=> {
    const o = list[uri]
    return Object.keys(o).reduce((obj,key)=> {
      if(o[key] !== undefined) {
        const value = Array.isArray(o[key]) ?
            o[key].map( u => {
              if(u.uri) {
                console.log(u.uri)
                let namespace = u.uri.split('/')
                const id = namespace.pop()
                namespace = namespace.join('/')+'/'
                console.log(namespace)
                const val = state[namespace] && state[namespace].list[id]
                if(val)
                  return val
              }
              return u
            })
          :
            o[key]
        if(value !== undefined) obj[key] = value
      }
      return obj
    },{})
  }
)

export default getPaddedObjectByType

export const createGetPaddedObjectByType = ()=> getPaddedObjectByType