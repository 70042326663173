import getResidencies from './getResidencies'
import getResidencyById from './getResidencyById'
import getObjectsByType from './getObjectsByType'
import getObjectByType from './getObjectByType'
import getObjectByUri from './getObjectByUri'
import getPaddedObjectByType from './getPaddedObjectByType'

export {
  getResidencies,
  getResidencyById,
  getObjectsByType,
  getObjectByType,
  getObjectByUri,
  getPaddedObjectByType,
}