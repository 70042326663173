import moment from 'moment'
import { createSelector } from 'reselect'

import { ANNOTATIONS } from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const idsSelector = (state,object_ids)=> object_ids

const getAnnotationsByOverlap = createSelector(
  [annotationsSelector,idsSelector],
  (annotations,object_ids)=> {
    console.log('getAnnotationsByOverlap')
    return Object.values(annotations).filter( a =>
      a.shows.find( b => object_ids.indexOf(b.id) !== -1 )
    )
})

export default getAnnotationsByOverlap

export const createGetAnnotationsByOverlap = ()=> getAnnotationsByOverlap