import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { history } from '|/store'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const Overlay = (props)=> {

  const [ overlay, setOverlay ] = useState(null)

  useEffect(()=> {
    const _container = props.container ? document.querySelector(props.container) : document.body
    const _overlay = document.createElement('div')
    _overlay.classList.add('overlay')
    if(props.container)
      _overlay.style.position = 'absolute'
    setOverlay(_overlay)
    _container.appendChild(_overlay)
    return ()=> {
      _container.removeChild(_overlay)
    }
  },[])

  const _handleClose = (e)=> {
    e.preventDefault()
    if(props.onClose)
      return props.onClose()
    history.goBack()
  }

  const { title, children, className } = props
  
  if(!overlay) return null
  return createPortal(
    <Fragment>
      <div
        className={ [ styles.BG ].join(' ') }
        onClick={ _handleClose }
      />
      <div className={ [ styles.container, (className ? className : null) ].join(' ') }>
        <div className={ styles.header }>
          <Plink
            className={ styles.close }
            onClick={ _handleClose }
          >
            &times;
          </Plink>
        </div>
        <div className={ styles.content }>
          { children }
        </div>
      </div>
    </Fragment>,
    overlay
  )
}

Overlay.propTypes = {
  round: PropTypes.bool,
  container: PropTypes.string,
  title: PropTypes.string,
}

Overlay.defaultProps = {
  round: false,
  container: undefined,
  title: undefined,
}

export default Overlay