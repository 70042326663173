import { useState, useEffect, useCallback } from 'react'

export default ()=> {

  const [ src, setSrc ] = useState(undefined)
  const [ video, setVideo ] = useState(undefined)
  const [ currentTime, setCurrentTime ] = useState(0)
  const [ muted, setMuted ] = useState(false)
  const [ canplay, setCanplay ] = useState(false)
  const [ onPlay, setOnPlay ] = useState(()=>{})
  const [ onPause, setOnPause ] = useState(()=>{})
  const [ playing, setPlaying ] = useState(false)
  const [ seeking, setSeeking ] = useState(undefined)
  const [ error, setError ] = useState(false)

  const _onCanplay = (e)=> {
    console.log('canplay',e.target)
    if(e.target) {
      setCanplay(true)
      setError(false)
    }
  }

  const _onVolumechange = (e)=> {
    if(video)
      setMuted(e.target.muted)
  }

  const _onSeeking = (e)=> {
    console.log('seeking',e.target.currentTime)
    if(video) {
      setCanplay(false)
      setSeeking(e.target.currentTime)
    }
  }

  const _onPause = (e)=> {
    console.log('pause')
    if(video) {
      setPlaying(false)
    }
  }

  const _onPlay = (e)=> {
    console.log('play')
    if(video) {
      setPlaying(true)
      setError(false)
    }
  }

  const _onPlaying = (e)=> {
    console.log('playing')
    setPlaying(true)
    setError(false)
  }

  const _onSeeked = (e)=> {
    console.log('seeked')
    if(video) {
      setCanplay(true)
      setSeeking(undefined)
      setError(false)
    }
  }

  const _onError = (e)=> {
    console.log(e)
    if(video) {
      setError('Error!')
      console.log(error)
    }
  }

  const _onStalled = (e)=> {
    console.log(e)
    if(video) {
      setError('Stalled!')
    }
  }

  const _onSuspend = (e)=> {
    console.log('Suspended:',e.target.readyState)
    if(video) {
      setError('Suspend!')
      // setCanplay(false)
    }
  }

  const _onWaiting = (e)=> {
    console.log('Waiting')
    if(video) {
      setCanplay(false)
    }
  }

  const _onTimeUpdate = (e)=> {
    if(video)
      setCurrentTime(e.target.currentTime)
  }
  
  const setRef = useCallback( node => {
    console.log('node',node)
    if(video) {
      video.removeEventListener('volumechange',_onVolumechange)
      video.removeEventListener('seeking',_onSeeking)
      video.removeEventListener('seeked',_onSeeked)
      video.removeEventListener('canplay',_onCanplay)
      video.removeEventListener('timeupdate',_onTimeUpdate)
      video.removeEventListener('pause',_onPause)
      video.removeEventListener('play',_onPlay)
      video.removeEventListener('playing',_onPlaying)
      video.removeEventListener('error',_onError)
      video.removeEventListener('stalled',_onStalled)
      video.removeEventListener('suspend',_onSuspend)
      video.removeEventListener('waiting',_onWaiting)
    }
    if(node) {
      node.addEventListener('volumechange',_onVolumechange)
      node.addEventListener('seeking',_onSeeking)
      node.addEventListener('seeked',_onSeeked)
      node.addEventListener('canplay',_onCanplay)
      node.addEventListener('timeupdate',_onTimeUpdate)
      node.addEventListener('pause',_onPause)
      node.addEventListener('play',_onPlay)
      node.addEventListener('playing',_onPlaying)
      node.addEventListener('error',_onError)
      node.addEventListener('stalled',_onStalled)
      node.addEventListener('suspend',_onSuspend)
      node.addEventListener('waiting',_onWaiting)
      node.src = src
      node.muted = muted
    }
    setVideo(node)
  },[ src ])
  
  return {
    video,
    setSrc,
    setMuted,
    setRef,
    currentTime,
    canplay,
    playing,
    seeking,
    error,
  }
}