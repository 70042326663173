import React, { createContext, useState } from 'react'

export const ZoomContext = createContext()

export const ZoomProvider = ({ children })=> {

  const [ zoom, setZoom ] = useState(0.01)

  const zoomContext = {
    zoom,
    setZoom,
  }

  return <ZoomContext.Provider
      value={ zoomContext }
    >
      { children }
    </ZoomContext.Provider>
}