import { createSelector } from 'reselect'

import { ACTIONS } from '|/settings'

const actionsSelector = state => state[ACTIONS].list

const getActions = (actions,key)=> {
  console.log('getActions')
  return Object.values(actions).filter( a => !a.is_group )
}

export default createSelector(
  [actionsSelector],
  getActions
)