import { createSelector } from 'reselect'

const listSelector = (state,type)=> state[type].list
const uriSelector = (state,type,uri)=> uri

const getObjectByType = createSelector(
  [listSelector,uriSelector],
  (list,uri)=> {
    // console.log('getObjectByType')
    return list[uri]
  }
)

export default getObjectByType

export const createGetObjectByType = ()=> getObjectByType