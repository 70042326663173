import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
// import { exposeStore } from 'redux-in-worker'
import localForage from 'localforage'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware, { END } from 'redux-saga'
import { createBrowserHistory } from 'history'

import createRootReducer from './reducers'

import {
  PROJECTS,
  TOOLS,
  MACHINES,
  ACTORS,
  LOCATIONS,
  MATERIALS,
  MEDIAS,
  ANNOTATIONS,
  ACTIONS,
  RESIDENCIES,
} from '|/settings'

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)
const sagaMW = createSagaMiddleware()
const routerMW = routerMiddleware(history)

const initState = {}
const enhancers = []
const middleware = [
	routerMW,
	sagaMW
]

if(process.env.DEVTOOLS) {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
	if(typeof devToolsExtension === 'function')
		enhancers.push(devToolsExtension())
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
)

const persistConfig = {
	key: 'root',
	storage: localForage,
	blacklist: [
		'router',
		'Auth',
    // [PROJECTS],
    // [TOOLS],
    // [MACHINES],
    // [ACTORS],
    // [LOCATIONS],
    // [MATERIALS],
    // [MEDIAS],
    // [ANNOTATIONS],
    // [ACTIONS],
    // [RESIDENCIES],
	],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
	persistedReducer,
	// rootReducer,
	initState,
	composedEnhancers
)

if(module.hot) {
	module.hot.accept('./reducers',()=> {
		const nextRootReducer = persistReducer(persistConfig, createRootReducer(history))
		// const nextRootReducer = createRootReducer(history)
		store.replaceReducer(nextRootReducer)
	})
}

store.runSagas = sagaMW.run

store.close = ()=> store.dispatch(END)

// exposeStore(store)

export const persistor = persistStore(store)

export default store