import unionBy from 'lodash/unionBy'
import { createSelector } from 'reselect'

import {
  ANNOTATIONS,
  ACTIONS,
  TOOLS,
  MACHINES,
  MATERIALS,
  LOCATIONS,
  ACTORS,
} from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const idsSelector = (state,object_ids,)=> object_ids
const typesSelector = (state,object_ids,types)=> types
const listsSelector = (state,object_ids,types)=> types.reduce((obj,t)=> ({ ...obj, [t]: state[t].list }),{})

const getObjectsByOverlap = createSelector(
  [annotationsSelector,idsSelector,typesSelector,listsSelector],
  (annotations,object_ids,types,lists)=> {
    console.log('getObjectsByOverlap')
    const records = Object.values(annotations).filter( a =>
      (a.shows ? a.shows : []).find( b => (object_ids && object_ids.length > 0) ? object_ids.indexOf(b.id) !== -1 : b )
    )
    .reduce((arr,a)=> {
      return unionBy(arr,a.shows,'id')
    },[])
    return types.reduce((obj,t)=> {
      return {
        ...obj,
        [t]: records.filter(({ id, type })=> type === t ).map(({ id, type })=> lists[t][id] ),
      }
    },{})
})

export default getObjectsByOverlap

export const createGetObjectsByOverlap = ()=> getObjectsByOverlap