import { createSelector } from 'reselect'

const stateSelector = (state,type)=> state[type]
const uriSelector = (state,type,uri)=> uri

const getObjectsByType = createSelector(
  [stateSelector,uriSelector],
  (state,uri)=> {
    // console.log('getObjectsByType')
    return state ? state.list[uri] : undefined
  }
)

export default getObjectsByType

export const createGetObjectsByType = ()=> getObjectsByType