import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_ACTIONS,
  PARSING_ACTIONS,
  PARSED_ACTIONS,
  PARSING_ACTIONS_FAILED
} from '|/store/constants'

import {
  ACTIONS
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const TYPES = lib.Namespace('http://zhdk.ch/hands_on/types/')
  const RDFS = lib.Namespace('http://www.w3.org/2000/01/rdf-schema#')
  const actions = lib.Namespace(ACTIONS)
  const in_group = 'http://zhdk.ch/hands_on/in_group/'
  const skos = lib.Namespace('http://www.w3.org/2004/02/skos/core#')

  store.each(undefined,undefined,TYPES('HO-PROZESS')).map((r)=> {
    const id = r.value.replace(ACTIONS,'')
    const d = data[id] = {
      id,
      ns: ACTIONS,
      label: or(store.any(
          r,
          actions('name')
        ),'value') || or(store.any(
          r,
          RDFS('label')
        ),'value'),
      has_annotations: store.any(
          undefined,
          HO('shows'),
          r,
        ) ? true : false,
      is_group: store.any(
          r,
          actions('group_name')
        ) ? true : false,
      position: parseInt(or(store.any(
          r,
          actions('position')
        ),'value')),
      groups: store.each(
          r,
          HO('in_group'),
        ).map( g => {
            return g.value.split('/').pop()
          }),
      preview_urls: store.each(
          r,
          HO('has_media'),
        ).map( a => {
          return or(store.any(
            a,
            HO('preview_url')
          ),'value')
        }),
    }
  })
  return data
}

function* parseProcess() {
  const actions = yield call(match)
  try {
    yield put({
      type: PARSED_ACTIONS,
      data: actions
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_ACTIONS_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseProcess),
  ])
}