import { createSelector } from 'reselect'

import { RESIDENCIES } from '|/settings'

const residenciesSelector = state => state[RESIDENCIES].list

const getResidencies = (residencies)=> {
  console.log('getResidencies')
  return Object.values(residencies)
}

export default createSelector(
  [residenciesSelector],
  getResidencies
)