import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import {
  PROJECTS,
  BIBLIOGRAPHY,
  TOOLS,
  MACHINES,
  ACTORS,
  ARTEFACTS,
  LOCATIONS,
  MATERIALS,
  MEDIAS,
  ANNOTATIONS,
  ACTIONS,
  RESIDENCIES,
  LIST_VALUES,
} from '|/settings'

import Auth from './auth/reducers'
import Artefacts from './Artefacts/reducers'
import Actors from './Actors/reducers'
import Actions from './Actions/reducers'
import Locations from './Locations/reducers'
import Materials from './Materials/reducers'
import Tools from './Tools/reducers'
import Machines from './Machines/reducers'
import Annotations from './Annotations/reducers'
import Medias from './Medias/reducers'
import Projects from './Projects/reducers'
import Bibliography from './Bibliography/reducers'
import Timelines from './Timelines/reducers'


export default (history)=> combineReducers({
	router: connectRouter(history),
	Auth,
  [ACTORS]: Actors,
  [ARTEFACTS]: Artefacts,
  [ACTIONS]: Actions,
  [LOCATIONS]: Locations,
  [MATERIALS]: Materials,
  [TOOLS]: Tools,
  [MACHINES]: Machines,
  [ANNOTATIONS]: Annotations,
  [MEDIAS]: Medias,
  [PROJECTS]: Projects,
  [BIBLIOGRAPHY]: Bibliography,
  [RESIDENCIES]: Timelines,
})