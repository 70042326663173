import { createSelector } from 'reselect'

import { ACTIONS } from '|/settings'

const actionsSelector = state => state[ACTIONS].list
const groupsSelector = (state,groups)=> groups

const getActionsByGroups = createSelector(
  [actionsSelector,groupsSelector],
  (actions,groups)=> {
    console.log('getActionsByGroups')
    if(groups !== undefined && !Array.isArray(groups)) groups = [groups]
    const results = Object.values(actions)
      .filter( a =>
        !a.is_group
          &&
        (!groups || groups.hasIn(a.groups))
      )
      .sort((a,b)=> {
        if(a.label < b.label)
          return -1
        if(a.label > b.label)
          return 1
        return 0
      })
    console.log(results)
    return results
})

export default getActionsByGroups

export const createGetActionsByGroups = ()=> getActionsByGroups