import React, { Fragment, useMemo, useState, useRef, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'

import { getPaddedObjectByType } from '|/store/Timelines/selectors'
import { useQuery } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'

import Overlay from '|/helpers/Overlay'
import Img from '|/helpers/Img'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

import {
  BLACKLIST
} from '|/settings'

const Zoom = props => {

  const { zid, ztype } = useQuery()
  if(!zid || !ztype) return null

  const pathState = usePathState()
  const object = useSelector( state => getPaddedObjectByType(state,ztype,zid))

  const _onCloseClick = (e)=> {
    const to = {
      search: {
        remove: {
          zid,
          ztype,
        }
      }
    }
    history.push(pathState.toString(pathState.merge(to)))
  }

  if(!object) return null
  
  console.log(object)

  return (
    <Overlay
      className={ styles.zoom }
      onClose={ _onCloseClick }
    >
      { (object.preview_urls && object.preview_urls.length > 0) &&
        <img className={ styles.thumbnail } src={ object.preview_urls[0] } alt=""/>
      }
      <div className={ styles.details }>
        { Object.keys(object).map((k,i)=> {
          if(BLACKLIST[ztype].indexOf(k) !== -1)
            return null
          const value = Array.isArray(object[k]) ?
              object[k].map((ov,j)=>
                ov.id && ov.label ?
                    <Plink
                      className={ styles.ref }
                      to={{
                        search: {
                          remove: {
                            zid,
                            ztype,
                          },
                          add: {
                            zid: ov.id,
                            ztype: ov.ns,
                          },
                        }
                      }}
                      key={`${k}-${i}-${j}`}
                    >
                      { ov.label }
                    </Plink>
                  :
                    <div
                      key={`${k}-${i}-${j}`}
                      className={ styles.ref }
                    >
                      { typeof ov === 'string' ? ov : `N/A` }
                    </div>
              )
            :
              <div
                key={`${k}-${i}`}
                className={ styles.ref }
              >
                { typeof object[k] === 'string' ? object[k] : `N/A` }
              </div>
          return (
            <div key={ `attribute-${k}` } className={ styles.attribute }>
              <div className={ styles.key }>{ k }</div>
              <div className={ styles.value }>{ value }</div>
            </div>
          )
        })}
      </div>
    </Overlay>
  )
}

export default Zoom