import moment from 'moment'
import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const timestampSelector = (state,timestamp)=> timestamp

const getVideosByTimestamp = (medias,timestamp)=> {
  console.log('getVideosByTimestamp',timestamp)
  const time = moment.unix(timestamp)
  return Object.values(medias).filter( m =>
    m.type === 'video'
     &&
    time.isBetween(moment(m.start),moment(m.end),'second','[)')
  )
}

export default createSelector(
  [mediasSelector,timestampSelector],
  getVideosByTimestamp
)