import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
  AUTHENTICATE,
  AUTHENTICATING,
  AUTHENTICATED,
  SET_REDIRECT,
  CLEAR_REDIRECT,
  IDENTIFY,
  IDENTIFYING,
  IDENTIFIED,
  LOG_IN,
  LOGGING_IN,
  LOGGED_IN,
  LOG_OUT,
  LOGGED_OUT,
  GO_OFFLINE
} from '|/store/constants'

function* authenticate() {
  yield put({
    type: AUTHENTICATING
  })
  let expiresIn
  let is_offline
  let error
  let token = yield call(storage.getItem,'token')
  let expiresOn = yield call(storage.getItem,'expiresOn')
  if(token) {
    const { token, expiresIn, error, is_offline } = yield call(api.refreshToken,token)
  } else {
    yield put({
      type: LOGGED_OUT,
      error: 'No token'
    })
  }
  if(token) {
    yield call(storage.setItem,'token',token)
    if(expiresIn) {
      expiresOn = new Date(new Date().getTime() + expiresIn * 1000)
      yield call(storage.setItem,'expiresOn',expiresOn)
    }
    if(expiresOn && expiresOn <= new Date()) {
      yield put({
        type: LOG_OUT,
        error: 'Token expired'
      })
    } else {
      yield put({
        type: AUTHENTICATED,
      })
      const user = yield call(api.getCurrentUser,token)
      yield put({
        type: IDENTIFIED,
        user
      })
    }
  } else if(is_offline) {
    yield put({
      type: GO_OFFLINE,
      error
    })
  } else if(error) {
    yield put({
      type: LOG_OUT,
      error
    })
  }
}

function* login({ email, password }) {
  yield put({
    type: LOGGING_IN
  })
  const { token, non_field_errors, detail, error } = yield call(api.login,email,password)
  if(token) {
    yield call(storage.setItem,'token',token)
    const user = yield call(api.getCurrentUser,token)
    yield put({
      type: AUTHENTICATED,
      user
    })
    yield put({
      type: IDENTIFIED,
      user
    })
  } else {
    yield put({
      type: LOG_OUT,
      error: 'Invalid credentials'
    })
  }
}

function* logout({ error }) {
  yield call(storage.removeItem,'token')
  yield call(storage.removeItem,'expiresOn')
  yield put({
    type: LOGGED_OUT,
    error
  })
}

export default function* auth(App) {
  yield all([
    takeEvery(AUTHENTICATE,authenticate),
    takeEvery(LOG_IN,login),
    takeEvery(LOG_OUT,logout)
  ])
}