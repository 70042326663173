import { createSelector } from 'reselect'

import { ANNOTATIONS } from '|/settings'

const annotationsSelector = state => state[ANNOTATIONS].list
const urisSelector = (state,annotation_uris)=> annotation_uris

const getAnnotationsByIds = createSelector(
  [annotationsSelector,urisSelector],
  (annotations,annotation_uris)=> {
    const annotations_by_id = annotation_uris.reduce((arr,uri)=> {
      const id = uri.replace(ANNOTATIONS,'')
      if(annotations[id])
        arr.push(annotations[id])
      return arr
    },[])
    return annotations_by_id
  }
)

export default getAnnotationsByIds

export const createGetAnnotationsByIds = ()=> getAnnotationsByIds