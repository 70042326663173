import { createSelector } from 'reselect'

const stateSelector = (state)=> state
const uriSelector = (state,uri)=> uri

const getObjectByUri = createSelector(
  [stateSelector,uriSelector],
  (state,uri)=> {
    // console.log('getObjectByUri')
    let namespace = uri.split('/')
    const id = namespace.pop()
    namespace = namespace.join('/')
    return state[namespace] && state[namespace][id]
  }
)

export default getObjectByUri

export const createGetObjectByUri = ()=> getObjectByUri