import moment from 'moment'
import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { history } from '|/store'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { usePlayer } from '|/components/Player'
import { useHighlight } from '|/contexts/Highlight'
import { Fa } from '|/icons'

import { createGetObjectByType } from '|/store/Timelines/selectors/getObjectByType'
import { createGetPrevAnnotationWithObject } from '|/store/Annotations/selectors/getPrevAnnotationWithObject'
import { createGetNextAnnotationWithObject } from '|/store/Annotations/selectors/getNextAnnotationWithObject'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import Img from '|/helpers/Img'

import styles from './main.styl'

const Record = ({ record, type })=> {

  const [ formattedId, setFormattedId ] = useState('')
  const [ formattedDuration, setFormattedDuration ] = useState('')

  const { highlight, lowlight } = useHighlight()
  const pathState = usePathState()
  const getPrevAnnotationWithObject = useMemo(createGetPrevAnnotationWithObject,[record.id,record.annotation_id])
  const prev = useSelector( state => getPrevAnnotationWithObject(state,record.id,record.annotation_id))
  const getNextAnnotationWithObject = useMemo(createGetNextAnnotationWithObject,[record.id,record.annotation_id])
  const next = useSelector( state => getNextAnnotationWithObject(state,record.id,record.annotation_id))
  const getObjectByType = useMemo(createGetObjectByType,[record.type,record.id])
  const object = useSelector( state => getObjectByType(state,record.type,record.id))

  const _onPrevClick = ()=> {
    const to = {
      center: {
        play: moment(prev.start).unix()
      }
    }
    history.push(pathState.toString(pathState.merge(to)))
  }

  const _onNextClick = ()=> {
    const to = {
      center: {
        play: moment(next.start).unix()
      }
    }
    history.push(pathState.toString(pathState.merge(to)))
  }

  useEffect(()=> {
    const id_array = record.id.split('/')
    setFormattedId(id_array[id_array.length - 1])
    setFormattedDuration(record.duration)
  },[ record ])

  if(!object) return null
  
  return (
    <div
      className={
        [
          styles.record
      ].join(' ')}
    >
      <Button
        disabled={ !prev }
        material={ false }
        link={ true }
        className={ styles.prev }
        onClick={ _onPrevClick }
        onMouseEnter={ (e)=> prev && highlight(`[data-annotation="${prev.id}"]`,'highlighted') }
        onMouseLeave={ (e)=> prev && lowlight(`[data-annotation]`,'highlighted') }
      ><Fa icon="chevron-left" /></Button>
      <Plink
        className={ styles.zoom }
        to={{
          search: {
            add: {
              zid: object.id,
              ztype: type,
            },
          }
        }}
      >
        <div className={ styles.id }>
          { formattedId }
        </div>
        { object.preview_urls && object.preview_urls.length > 0 ?
            <img className={ styles.thumbnail } src={ object.preview_urls[0] } />
          :
            <div className={ styles.label }>
              { object.label }
            </div>
        }
        <div className={ styles.duration }>
          { formattedDuration }
        </div>
      </Plink>
      <Button
        disabled={ !next }
        material={ false }
        link={ true }
        className={ styles.next }
        onClick={ _onNextClick }
        onMouseEnter={ (e)=> next && highlight(`[data-annotation="${next.id}"]`,'highlighted') }
        onMouseLeave={ (e)=> next && lowlight(`[data-annotation]`,'highlighted') }
      ><Fa icon="chevron-right" /></Button>
    </div>
  )
}

Record.defaultProps = {
  reference: PropTypes.object
}

export default Record