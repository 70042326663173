import React, { useState, useEffect } from 'react'

import { useHighlight } from '|/contexts/Highlight'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const Action = ({ action, selected })=> {

  const { highlighted, highlight, lowlight } = useHighlight()
  const [ is_highlighted, setIs_highlighted ] = useState(false)

  // useEffect(()=> {
  //   setIs_highlighted(highlighted && highlighted.indexOf(action.id) !== -1)
  // },[ highlighted ])

  return (
    <div
      className={[
        styles.action,
        selected ? styles.selected : undefined,
        // is_highlighted ? styles.highlighted : undefined,
        action.has_annotations ? styles.enabled : undefined,
      ].join(' ') }
    >
      { action.has_annotations ?
          <Plink
            seo={ false }
            key={ action.id }
            to={{
              search: {
                toggle: {
                  action_ids: action.id
                }
              }
            }}
            onMouseEnter={ e => highlight(`[data-action="${action.id}"]`,'highlighted') }
            onMouseLeave={ e => lowlight(`[data-action="${action.id}"]`,'highlighted') }
          >
            <div className={ styles.label }>{ `${action.label}` }</div>
          </Plink>
        :
          <div className={ styles.label }>{ `${action.label}` }</div>
      }
    </div>
  )
}

export default Action