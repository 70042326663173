import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { ZoomContext } from '|/contexts/Zoom'

import Plink from '|/helpers/Plink'

import Record from './Record'

import {
  LABELS,
} from '|/settings'

import styles from './main.styl'

const Type = ({ records, uri })=> {

  return (
    <div
      className={ styles.type }
    >
      <div className={ styles.label }>{ LABELS[uri] }</div>
      { records && records.map((record)=>
        <Record key={ `${record.annotation_id}-${record.id}` } type={ uri } record={ record } />
      )}
    </div>
  )
}

Type.defaultProps = {
  annotation: PropTypes.object
}

export default Type