import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import Type from './Type'

import styles from './main.styl'

const Types = ({ types })=> {

  console.log('TYPES',types)

  return (
    <div className={ styles.types }>
      { Object.keys(types).map( key => 
        <Type key={ key } uri={ `${key}` } records={ types[key] } />
      )}
    </div>
  )
}

Types.defaultProps = {
  types: PropTypes.object
}

export default Types