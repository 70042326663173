import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { ResidencyContext } from '|/contexts/Residency'
import { ZoomContext } from '|/contexts/Zoom'

import Artefacts from './Artefacts'
import Annotations from './Annotations'

import styles from './main.styl'

const Video = ({ video, hour })=> {

  const { zoom } = useContext(ZoomContext)
  const { start, end, duration } = useContext(ResidencyContext)
  const [ relative_start, setRelative_start ] = useState(0)
  const [ video_duration, setVideo_duration ] = useState(0)

  useEffect(()=> {
    if(duration && video && hour) {
      const video_start = moment(video.start)
      const relative_start = moment.duration(video_start.diff(hour)).asSeconds()
      const video_duration = moment.duration(video.duration).asSeconds()

      setRelative_start(relative_start)
      setVideo_duration(video_duration)
    }
  },[ duration, video, hour ])

  return (
    <div
      className={ styles.video }
      style={{
        top: `${relative_start/3600*100}%`,
        height: `${video_duration/3600*100}%`,
      }}
    >
    </div>
  )
}

Video.defaultProps = {
  annotation: PropTypes.object
}

export default Video