export const TURTLE_URL = process.env.TURTLE_URL

export const BIBLIOGRAPHY = 'http://zhdk.ch/hands_on/bibliography/'
export const ANNOTATIONS = 'http://zhdk.ch/hands_on/annotation/'
export const PROJECTS = 'http://zhdk.ch/hands_on/projects/'
export const RESIDENCIES = 'http://zhdk.ch/hands_on/residency/'
export const TOOLS = 'http://zhdk.ch/hands_on/tools/'
export const MACHINES = 'http://zhdk.ch/hands_on/machines/'
export const ACTIONS = 'http://zhdk.ch/hands_on/processes/'
export const LOCATIONS = 'http://zhdk.ch/hands_on/places/'
export const ARTEFACTS = 'http://zhdk.ch/hands_on/artifacts/'
export const MATERIALS = 'http://zhdk.ch/hands_on/materials/'
export const MEDIAS = 'http://zhdk.ch/hands_on/media/'
export const ACTORS = 'http://zhdk.ch/hands_on/actors/'
export const LIST_VALUES = 'http://zhdk.ch/hands_on/list_values'

export const LABELS = {
  [BIBLIOGRAPHY]: 'Bibliografie',
  [PROJECTS]: 'Projekt',
  [RESIDENCIES]: 'Residency',
  [TOOLS]: 'Werkzeug',
  [MACHINES]: 'Maschine',
  [ACTIONS]: 'Prozess',
  [LOCATIONS]: 'Ort',
  [ARTEFACTS]: 'Artefakt',
  [MATERIALS]: 'Material',
  [ACTORS]: 'Akteur',
}

export const BLACKLIST = {
  [BIBLIOGRAPHY]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [PROJECTS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [RESIDENCIES]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [TOOLS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [MACHINES]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [ACTIONS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [LOCATIONS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [ARTEFACTS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [MATERIALS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
  [ACTORS]: [
      'id',
      'ns',
      'label',
      'preview_urls',
      'undefined',
    ],
}