import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Artefact from './Artefact'

import styles from './main.styl'

const Artefacts = ({ artefacts })=> {

  return (
    <div className={ `${styles.artefacts}` }>
      { artefacts.map((artefact)=>
        <Artefact key={ artefact.id } artefact={ artefact } />
      )}
    </div>
  )
}

Artefacts.defaultProps = {
  video: PropTypes.object
}

export default Artefacts