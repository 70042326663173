import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './main.styl'

const Img = props => {
  const [ container, setContainer ] = useState(null)
  const [ img, setImg ] = useState(null)
  let _image = null

  const onImageLoaded = ()=> {
    img.setAttribute('style',
      `background-image: url(${props.src});`
    )
    if(props.width === undefined && props.height === undefined)
      container.setAttribute('style',
        `width: ${_image.width}px;
        height: ${_image.height}px;`
      )
    container.classList.add(styles.loaded)
  }

  const getImg = ()=> {
    if(props.src && img) {
      _image = new Image()
      _image.src = props.src
      _image.onload = onImageLoaded
    }
  }

  useEffect(()=> {
    getImg()
  },[img])

  const {
    className,
    width,
    height,
    cover
  } = props
  const classList = [styles.container,'img']
  if(cover) classList.push(styles.cover)
  return (
    <div
      className={ `${classList.join(' ')} ${className || ''}` }
      ref={ el => setContainer(el) }
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      <div
        className={ styles.loader }
        style={{
          backgroundImage: `url(${props.preload})`
        }}
      />
      <div
        className={ styles.img }
        ref={ el => setImg(el) }
      />
    </div>
  )
}

Img.propTypes = {
  src: PropTypes.string,
  preload: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  cover: PropTypes.bool,
}

Img.defaultProps = {
  preload: '',
}

export default Img