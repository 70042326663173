import React, { Fragment, useMemo, useState, useRef, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'

import { getVideosByTimestamp } from '|/store/Medias/selectors'
import {
  getAnnotationsByTimestamp,
  getObjectsWithAnnotationByAnnotationsByTypes
} from '|/store/Annotations/selectors'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { useVideo } from '|/hooks'

import {
  ACTIONS,
  ACTORS,
  ARTEFACTS,
  LOCATIONS,
  MACHINES,
  MATERIALS,
  PROJECTS,
  TOOLS,
} from '|/settings'

import Player, { usePlayer } from '|/components/Player'

import Types from './Types'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'

import styles from './main.styl'

const Window = props => {

  const [ types ] = useState([
    ACTORS,
    ACTIONS,
    ARTEFACTS,
    LOCATIONS,
    MACHINES,
    MATERIALS,
    PROJECTS,
    TOOLS,
  ])

  const params = useParams()
  console.log(params)
  const { timestamp } = params
  const pathState = usePathState()
  const [ ts, setTs ] = useState(timestamp)
  const [ annotation_ids, setAnnotation_ids ] = useState([])
  const [ video_ids, setVideo_ids ] = useState([])
  const { jumpTo, currentTime } = usePlayer()
  const videos = useSelector( state => getVideosByTimestamp(state,ts) )
  const annotations = useSelector( state => getAnnotationsByTimestamp(state,ts) )
  const recordsByType = useSelector( state => getObjectsWithAnnotationByAnnotationsByTypes(state,annotation_ids,types) )

  useEffect(()=> {
    if(currentTime)
      setTs(currentTime)
  },[ currentTime ])

  useEffect(()=> {
    const a_ids = annotations.reduce((arr,a)=> ( arr.push(a.id), arr),[])
    if(!isEqual(annotation_ids,a_ids))
      setAnnotation_ids(a_ids)
  },[ annotations ])

  useEffect(()=> {
    const v_ids = videos.reduce((arr,v)=> ( arr.push(v.id), arr),[])
    // console.log('TS',ts)
    // console.log('videos',videos)
    // console.log('v_ids',v_ids)
    if(!isEqual(v_ids,video_ids)) {
      if(currentTime > 0) {
        jumpTo(currentTime)
      }
      setVideo_ids(v_ids)
    }
  },[ videos ])

  if(!videos) return null

  const _onWindowClick = (e)=> {
    const to = {
      left: {
        s: 'min'
      },
      right: {
        s: 'min'
      },
    }
    history.push(pathState.toString(pathState.merge(to)))
  }

  const _onRewClick = ()=> {
    const next_timestamp = currentTime - 30
    jumpTo(next_timestamp)
  }

  const _onFfwdClick = ()=> {
    const prev_timestamp = currentTime + 30
    jumpTo(prev_timestamp)
  }

  const cachedTypes = useMemo(()=> <Types types={ recordsByType } /> ,[annotation_ids])

  const cachedPlayer = useMemo(()=> <Player timestamp={ timestamp } videos={ videos } /> ,[timestamp,video_ids])

  return (
    <div
      className={ styles.window }
      onClick={ _onWindowClick }
    >
      <div
        className={ styles.content }
      >
        { videos.length > 0 &&
          <Button
            className={ [ styles.skip, styles.rew].join(' ') }
            onClick={ _onRewClick }
          >&uarr; 30 sec</Button>
        }
        <div className={ styles.videos }>
          { cachedPlayer }
        </div>
        { videos.length > 0 &&
          <Button
            className={ [ styles.skip, styles.ffwd].join(' ') }
            onClick={ _onFfwdClick }
          >&darr; 30 sec</Button>
        }
        { videos.length > 0 ?
            cachedTypes
          :
            <div className={ styles.noVideo }>Please select a part of the timeline containing at least one video</div>
        }
      </div>
    </div>
  )
}

export default Window