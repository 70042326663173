import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'

import { useHighlight } from '|/contexts/Highlight'

import { createGetObjectByType } from '|/store/Timelines/selectors/getObjectByType'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const Action = ({ reference })=> {

  const {
    id,
    type
  } = reference

  // const { highlighted } = useHighlight()
  // const { action_group_ids, action_ids } = useQuery()
  const getObjectByType = useMemo(createGetObjectByType,[reference])
  const object = useSelector( state => getObjectByType(state,type,id))
  const [ hover, setHover ] = useState(false)
  const [ highlight, setHighlight ] = useState(false)
  const [ selected, setSelected ] = useState(false)

  if(!object) return null

  // useEffect(()=> {
  //   // console.log(highlighted)
  //   const should_highlight = highlighted.indexOf(object.id) !== -1
  //   if(should_highlight && !highlight || !should_highlight && highlight)
  //     setHighlight(should_highlight)
  // },[ highlighted ])

  // useEffect(()=> {
  //   if(object) {
  //     const ag_array = Array.isArray(action_group_ids) ? action_group_ids : [action_group_ids]
  //     const a_array = Array.isArray(action_ids) ? action_ids : [action_ids]
  //     const id_array = [ ...ag_array, ...a_array ]
  //     setSelected(
  //       (id_array.indexOf(object.id) !== -1)
  //         ||
  //       (object.groups && id_array.hasIn(object.groups))
  //     )
  //   }
  // }, [ action_group_ids, action_ids ])

  // useEffect(()=> {
  //   if(object) {
  //     const a_array = Array.isArray(action_ids) ? action_ids : [action_ids]
  //     if(a_array.indexOf(object.id) !== -1)
  //       setSelected(true)
  //     else
  //       setSelected(false)
  //   }
  // }, [ action_ids ])

  const onMouseEnter = (e)=> {
    // setHover(true)
  }

  const onMouseLeave = (e)=> {
    // setHover(false)
  }
  
  return (
    <div
      data-action={ id }
      data-action-groups={ (object.groups ? object.groups : []).join(';') }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      className={
        [
          styles.action,
          // hover ? styles.hover : undefined,
          // highlight ? styles.highlighted : undefined,
          // selected ? styles.selected : undefined,
      ].join(' ')}
    />
  )
}

Action.defaultProps = {
  reference: PropTypes.object
}

export default Action