import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Annotation from './Annotation'

import styles from './main.styl'

const Annotations = ({ annotations, hour })=> {

  return (
    <ul className={ `${styles.annotations}` }>
      { annotations.map((annotation,i)=>
        <Annotation key={ annotation.id } annotation={ annotation } hour={ hour } />
      )}
    </ul>
  )
}

Annotations.defaultProps = {
  hour: PropTypes.object
}

export default Annotations