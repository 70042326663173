import { createSelector } from 'reselect'

import { ACTIONS } from '|/settings'

const actionsSelector = state => state[ACTIONS].list
const idSelector = (state,id)=> id

const getProcess = (actions,id)=> {
  console.log('getProcess')
  return Object.values(actions).reduce((obj,action)=> {
    return {
      ...obj,
      [action.group]: (obj[action.group] ? [
        ...obj[action.group],
        action
      ] : [action])
    }
  },{})
}

export default createSelector(
  [actionsSelector,idSelector],
  getProcess
)