import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'

import { getResidencies, getResidencyById } from '|/store/Timelines/selectors'
import { useParams } from '|/hooks/router'
import { ZoomProvider } from '|/contexts/Zoom'
import { usePathState } from '|/hooks/pathState'

import Plink from '|helpers/Plink'

import Residency from './Residency'

import styles from './main.styl'

const Residencies = (props)=> {

  const { residency_id } = useParams()
  const pathState = usePathState()
  const residencies = useSelector( state => getResidencies(state) )
  const currentResidency = useSelector( state => getResidencyById(state,residency_id) )

  useEffect(()=> {
    if(residency_id == 0 && residencies[0]) {
      const to = {
        residency_id: residencies[0].id
      }
      history.push(pathState.toString(pathState.merge(to)))
    }
  },[ residency_id, residencies ])

  return (
    <Fragment>
      <div className={ styles.header }>
        <h1>Timeline</h1>
        <div className={ styles.dropdown }>
          { residencies.map((residency)=>
            <div
              className={[ styles.option, residency.id === residency_id ? styles.active : undefined ].join(' ')}
              key={`${residency.id}`}
            >
              <Plink
                to={{
                  residency_id: residency.id
                }}
              >
                { residency.name }
              </Plink>
            </div>
          )}
        </div>
      </div>
      <div className={ styles.residencies }>
        { currentResidency &&
          <ZoomProvider>
            <Residency key={ currentResidency.id } residency={ currentResidency } />
          </ZoomProvider>
        }
      </div>
    </Fragment>
  )
}

export default Residencies