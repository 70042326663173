import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const idSelector = (state,id)=> id

const getVideo = (medias,id)=> {
  console.log('getVideo')
  return Object.values(medias).filter( m => m.id === id )
}

export default createSelector(
  [mediasSelector,idSelector],
  getVideo
)