import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { getStore, lib } from '|/store/rdf'

import {
  PARSED_TURTLE,
  PARSE_MACHINES,
  PARSING_MACHINES,
  PARSED_MACHINES,
  PARSING_MACHINES_FAILED
} from '|/store/constants'

import {
  MACHINES,
  LIST_VALUES,
} from '|/settings'

const store = getStore()

function* match(q,context) {
  const data = {}
  const HO = lib.Namespace('http://zhdk.ch/hands_on/')
  const types = lib.Namespace('http://zhdk.ch/hands_on/types/')
  const machines = lib.Namespace(MACHINES)
  const rdfs = lib.Namespace('http://www.w3.org/2000/01/rdf-schema#')
  const skos = lib.Namespace('http://www.w3.org/2004/02/skos/core#')
  store.each(null,null,types('HO-MASCHINE')).map((r)=> {
    // console.log(r.value)
    const id = r.value.replace(MACHINES,'')
    const d = data[id] = {
      id,
      ns: MACHINES,
      label: or(store.any(
          r,
          machines('umgangssprachliche-bezeichnung')
        ),'value'),
      preview_urls: store.each(
          r,
          HO('has_media'),
        ).map( a => {
          return or(store.any(
            a,
            HO('preview_url')
          ),'value')
        }).filter( b => b ),
    }
    store.each(
      r
    )
    .map((key)=> (
      {
        key,
        position: or(store.any(
          key,
          skos('position')
        ),'value')
      }
    ))
    .sort((a,b)=> a.position - b.position )
    .map(({key})=> {
      const label = or(store.any(
        key,
        rdfs('label')
      ),'value')
      data[id][label] = store.each(
        r,
        key,
      ).map( r => {
        if(r.termType === 'NamedNode') {
          if(r.value.indexOf(LIST_VALUES) !== -1)
            return or(store.any(
              r,
              rdfs('label')
            ),'value')
          return {
            uri: r.value
          }
        }
        if(r.value)
          return r.value
      })
    })
  })
  return data
}

function* parseTools() {
  yield put({
    type: PARSING_MACHINES,
  })
  const tools = yield call(match)
  try {
    yield put({
      type: PARSED_MACHINES,
      data: tools
    })
  } catch(e) {
    console.log(e);
    yield put({
      type: PARSING_MACHINES_FAILED,
      error: 'Something went wrong parsing turtle'
    })
  }
}

export default function* cat(App) {
  yield all([
    takeEvery(PARSED_TURTLE,parseTools),
  ])
}