import moment from 'moment'
import { createSelector } from 'reselect'

import { MEDIAS } from '|/settings'

const mediasSelector = state => state[MEDIAS].list
const hourSelector = (state,hour)=> hour

const getVideosWithinHour = (medias,hour)=> {
  // console.log('getVideosWithinHour',start,end)
  return Object.values(medias).filter( m =>
    m.type === 'video'
     &&
    moment(m.start).isBetween(hour,hour.clone().add(1,'hours'))
  )
}

export default createSelector(
  [mediasSelector,hourSelector],
  getVideosWithinHour
)