import storage from './utils/storage'

import {
	API_PATH,
	TURTLE_URL
} from '|/settings'

const authHeader = (token)=> `JWT ${token.trim()}`

const handleNetworkError = (err)=> {
	return {
		error: 'Network error. Please try again.',
		is_offline: true
	}
}

const api = {
	// login(email, password) {
	// 	const url = `${API_PATH}/signin/`
	// 	return fetch(url,{
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		},
	// 		body: JSON.stringify({
	// 			email,
	// 			password
	// 		})
	// 	})
	// 	.then( res => res.ok ? res.json() : { error: 'Login failed' } )
	// 	.catch(handleNetworkError)
	// },
	// getCurrentUser(token) {
	// 	const url = `${API_PATH}/user/`
	// 	return fetch(url,{
	// 		headers: { 
	// 			'Authorization': authHeader(token)
	// 		}
	// 	})
	// 	.then( res => res.ok ? res.json() : { error: 'Failed to fetch user details' })
	// 	.catch(handleNetworkError)
	// },
	// refreshToken(token) {
	// 	if(!token)
	// 		return {
	// 			error: 'No token'
	// 		}
	// 	const url = `${API_PATH}/refresh-token/`
	// 	return fetch(url,{
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		},
	// 		body: JSON.stringify({
	// 			token
	// 		})
	// 	})
	// 	.then( res => res.ok ? res.json() : { error: 'Token authentication failed' } )
	// 	.catch(handleNetworkError)
	// },
	fetchCat(token) {
		// if(!token)
		// 	return {
		// 		error: 'No token'
		// 	}
		const url = CAT_API_PATH
		return fetch(url,{
			// headers: { 
			// 	'Authorization': authHeader(token)
			// }
		})
		.then( res => res.json() )
		.catch(handleNetworkError)
	},
	fetchTurtle(token) {
		// if(!token)
		// 	return {
		// 		error: 'No token'
		// 	}
		const url = TURTLE_URL
		return fetch(url,{
			// headers: { 
			// 	'Authorization': authHeader(token)
			// }
			mode: 'no-cors'
		})
		.then( res => res.ok ? res.text() : { error: 'Failed to fetch users' })
		.catch(handleNetworkError)
	},
}

module.exports = api