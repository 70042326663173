import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useHighlight } from '|/contexts/Highlight'

import { createGetObjectsByOverlap } from '|/store/Annotations/selectors/getObjectsByOverlap'

import { Fa } from '|/icons'
import Img from '|/helpers/Img'
import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'

import {
  TOOLS,
  MATERIALS,
  MACHINES,
  LOCATIONS,
  ACTORS,
  ARTEFACTS,
  PROJECTS,
  LABELS,
} from '|/settings'

import styles from './main.styl'

const Records = ({ actionIds, recordIds })=> {

  const { highlighted, highlight, lowlight } = useHighlight()

  const [ a_ids, setA_ids ] = useState([])
  const [ selected_ids, setSelected_ids ] = useState([])
  const [ list, setList ] = useState(undefined)
  const [ type, setType ] = useState(undefined)
  const [ types ] = useState([
    ACTORS,
    ARTEFACTS,
    LOCATIONS,
    MACHINES,
    MATERIALS,
    PROJECTS,
    TOOLS,
  ])
  const [ typeRenderings ] = useState({
    [ACTORS]: {
      icon: 'users'
    },
    [ARTEFACTS]: {
      icon: 'image'
    },
    [LOCATIONS]: {
      icon: 'globe'
    },
    [MACHINES]: {
      icon: 'digital-tachograph'
    },
    [MATERIALS]: {
      icon: 'shapes'
    },
    [PROJECTS]: {
      icon: 'chart-line'
    },
    [TOOLS]: {
      icon: 'tools'
    },
  })

  const getObjectsByOverlap = useMemo(createGetObjectsByOverlap,[ a_ids ])
  const recordTypes = useSelector( state => getObjectsByOverlap(state,a_ids,types) )

  useEffect(()=> {
    if(list) {
      const id_array = Array.isArray(recordIds) ? recordIds : [recordIds]
      const new_selected_ids = list.reduce((arr,{ id })=> {
        if(id_array.indexOf(id) !== -1)
          arr.push(id)
          return arr
      },[])
      if(!isEqual(new_selected_ids,selected_ids)) {
        setSelected_ids(new_selected_ids)
      }
    }
  }, [ recordIds, list ])

  useEffect(()=> {
    if(!isEqual(actionIds,a_ids)) {
      setA_ids(actionIds)
    }
  },[ actionIds ])

  const toggleList = (t,l)=> {
    if(t && l && !list || type !== t) {
      setList(l)
      setType(t)
    } else {
      setList(undefined)
      setType(undefined)
    }
  }

  return (
    <div
      className={[
        styles.records,
      ].join(' ') }
    >
      <div className={ styles.toggles }>
        { Object.keys(recordTypes).map( t =>
          <Plink
            key={ t }
            to={{
              left: {
                s: 'max'
              }
            }}
          >
            <Button
              round={ true }
              material={ false }
              data-type={ t }
              disabled={ recordTypes[t].length === 0 }
              className={ t === type ? 'active' : '' }
              onClick={ (e)=> toggleList(t,recordTypes[t]) }
            >
              <div className={ styles.icon }>
                <Fa style="fas" icon={ typeRenderings[t].icon } />
              </div>
              <div className={ styles.count }>{ recordTypes[t].length }</div>
            </Button>
          </Plink>
        )}
      </div>
      { list &&
        <div className={ styles.panel }>
          <div className={ styles.header }>
            <Button
              material={ false }
              link={ true }
              onClick={ e => toggleList() }
            ><Fa icon="times" /></Button>
            <h1>{ LABELS[type] }</h1>
          </div>
          <div className={ styles.list }>
            { list.map( r =>
                <div
                  data-records={ r.id+';' }
                  key={ r.id }
                  className={[
                    styles.record,
                    (selected_ids && selected_ids.indexOf(r.id) !== -1 ? styles.selected : undefined )
                  ].join(' ')}
                  onMouseEnter={ e => highlight(`[data-records*="${r.id};"]`,'highlighted') }
                  onMouseLeave={ e => lowlight(`[data-records*="${r.id};"]`,'highlighted') }
                >
                  <Plink
                    className={ styles.open }
                    seo={ false }
                    to={{
                      search: {
                        toggle: {
                          record_ids: r.id
                        }
                      }
                    }}
                  >
                    { (r.preview_urls && r.preview_urls.length > 0) &&
                      <Img className={ styles.thumbnail } src={ r.preview_urls[0] } alt=""/>
                    }
                    <div className={ styles.label }>{ r.label }</div>
                  </Plink>
                  <Plink
                    className={ styles.zoom }
                    to={{
                      search: {
                        zid: r.id,
                        ztype: type,
                      }
                    }}
                  >
                    <Button
                      material={ false }
                      round={ true }
                    >
                      <Fa style="far" icon="ellipsis-h" />
                    </Button>
                  </Plink>
                </div>
            )}
          </div>
        </div>
      }
    </div>
  )
}

export default Records