import {
  PARSING_TIMELINES,
  PARSED_TIMELINES
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case PARSING_TIMELINES:
      return {
        ...state,
        loading: true,
      }
    case PARSED_TIMELINES:
      const { data } = action
      return {
        ...state,
        loading: false,
        list: {
          ...data
        },
      }
  }
  return state
}