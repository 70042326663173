import React, { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/router'

import { getActionGroups } from '|/store/Actions/selectors'

import ActionGroup from './ActionGroup'

import styles from './main.styl'

const ActionGroups = ({ actionGroupIds })=> {

  const actionGroups = useSelector( state => getActionGroups(state) )
  const [ selected_ids, setSelected_ids ] = useState([])
  const [ hasSelected, setHasSelected ] = useState(false)

  useEffect(()=> {
    const id_array = Array.isArray(actionGroupIds) ? actionGroupIds : [actionGroupIds]
    const new_selected_ids = actionGroups.reduce((arr,{ id })=> {
      if(id_array.indexOf(id) !== -1)
        arr.push(id)
        return arr
    },[])
    if(!isEqual(new_selected_ids,selected_ids)) {
      setSelected_ids(new_selected_ids)
    }
  }, [ actionGroupIds ])

  console.log(actionGroups)

  // useEffect(()=> {
  //   const id_array = Array.isArray(action_ids) ? action_ids : [action_ids]
  //   const new_highlighted_ids = actionGroups.reduce((arr,{ id })=> {
  //     if(id_array.indexOf(id) !== -1)
  //       arr.push(id)
  //       return arr
  //   },[])
  //   if(!isEqual(new_highlighted_ids,highlighted_ids)) {
  //     setHighlighted_ids(new_highlighted_ids)
  //   }
  // }, [ action_ids ])

  return (
    <div className={ [ styles.actions, styles.groups ].join(' ') }>
      { actionGroups.map((actionGroup)=> {
        const selected = selected_ids && selected_ids.indexOf(actionGroup.id) !== -1
        return <ActionGroup
          key={ actionGroup.id }
          selected={ selected }
          actionGroup={ actionGroup }
        />
      })}
    </div>
  )
}

export default ActionGroups